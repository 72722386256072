/* globals Audio, localStorage */
import Vue from '@vue/compat';
import { reactive } from 'vue';
import { EventBus } from './bus';
import { aDelay } from '../lib/asyncUtil';
import { isMobile } from "../lib/mobileUtil";
import { hashValue, wsCall, getOwnUUID } from '../lib/wsConnect';
import { removedSplashPromise } from "../lib/splashAndTheme";
// import { removeStreamToAllConnections, closeUserMedia, closeDisplayMedia } from '../lib/rtcCall';
// import { removeStreamToAllConnections } from '../lib/rtcConn';
import { closeUserMedia, closeDisplayMedia } from '../lib/mediaDevices';
import { v4 as uuidv4 } from 'uuid';
import * as PageTitleNotification from '../lib/pageTitleNotification';
import { savedPersistedStateEvent } from '../effector/persisted';
import { allUsersState, receivedAllUserStateEvent } from '../effector/users';
import { YOUTUBE_API_KEY_DEFAULT, YOUTUBE_CLIENT_ID_DEFAULT} from '../../sharedsrc/constants'

const hasOwn = Object.prototype.hasOwnProperty;

const store = {
  state: {
    isGuestOrBeacon: undefined,
    speakerRequests: [],
    wasInARoom: null,
    forceRelay: false,
    remoteFullSize: false,
    remoteToFullSizeUUID: null,
    remoteToFullSizeObject: null,
    ensureUserMediaLock: false,
    packageCommit: process.env.PACKAGE_COMMIT,
    packageVersion: process.env.PACKAGE_VERSION,
    ownModalUser: true,
    userOnModal: undefined,
    notificationsShouldAsk: false,
    notificationsSubscribed: false,
    sendInvitationGuestModal: {
      newInvitation: false,
      invitationData: {}
    },
    showInviteInstaLink: false,
    showInvitationSent: false,
    showIncomingTickets: false,
    showOutgoingTickets: false,
    showArchivedIncomingTickets: false,
    showArchivedOutgoingTickets: false,
    showInfoBoardTicket: false,
    organizationObject: undefined,
    showOrganization: false,
    lastUserStateSent: undefined,
    showAddNoteModal: false,
    isConfDeleting: false,
    dev: false,
    nerd: true,
    // showSplash: true,
    wsOpen: false,
    wsStatus: 'Init',
    ownUUID: 'Loading...',
    isAreaSearch: false,
    searchTerm: '',
    showSearch: false,
    orgaSearchTerm: {
      searching: '',
      type: ''
    },
    currentTS: Date.now(),
    showUserTickets: false,
    showUserTicketsModal: false,
    simpleOrganisationManagerVisible: false,
    showTicketDetails: false,
    // showTicketEntry: false,
    ticketsInCount: 0,
    ticketsOutCount: 0,
    activeTicketNotes: [],
    activeTicketUser: '',
    activeTicket: {},
    activeTicketPercentDone: '0',
    allowSaveTicket: true,
    ticketSearchTerm: '',
    ticketViewType: '',
    ticketEntryTitle: 'Create new ticket',
    recorSavedMssg: '',
    toggleSidebarCall: false,
    isCallFull: false,
    spaceBarOriging: '',
    persisted: {
      tokenExchange: undefined,
      isDark: undefined,
      ignoreRecoveryPhone: false,
      itemsPerPageStatus: 5,
      showSidebar: undefined,
      usersCustomStatus: [],
      userOneCallTariffs: [],
      userMssgWhenActive: [],
      usersCustomAppointmentEntries: [],
      userVisitors: {},
      interactionHistory: {},
      userMessages: {
        messages: {}
      },
      totalNotification: 0,
      totalNotificationCall: 0,
      totalNotificationTicket: 0,
      totalNotificationFile: 0,
      totalNotificationInvite: 0,
      totalNotificationMessage: 0,
      favoriteUsers: {},
      invitedUsers: {},
      guestInfoConfigDone: false,
      rtcAudio: true,
      rtcVideo: true,
      showWelcomeMesagge: true,
      mediaFirstSetupDone: false,
      lastPurgeDate: null,
      mediaDeviceSetup: {
        audioDeviceId: undefined,
        videoDeviceId: undefined,
        audioOutputId: undefined,
        ringingOutputId: undefined,
        volumeCallTone: 100,
        videoBackground: undefined
      },
      userUploadedExcel: false,
      temporalAvatar: undefined,
      invitationsSent: [],
      rtcSettings: {
        useVP9: true,
        videoBitrate: 8000,
        audioBitrate: 1000
      },
      callHistory: {
        groupCalls: [],
        incoming: [],
        outgoing: []
      },
      userUsedTags: [],
      useBlurEffect: false,
      rtcVideoQuality: 'medium',
      tmpMessages: {
        showMessageInMeetingTimeModal: true,
        showInfoIncomingCallModal: true,
        showMessageInitiateCallSpace: true,
        showMessageUpdateProVersionInConference: true,
      },
      reactToDeviceChanges: false,
      customInviteUserMessage: '',
      customInviteGuestMessage: '',
      customVisitorInviteText: '',
      appointmentDate: 0,
      appointment: {
        '8:00': {},
        '8:15': {},
        '8:30': {},
        '8:45': {},
        '9:00': {},
        '9:15': {},
        '9:30': {},
        '9:45': {},
        '10:00': {},
        '10:15': {},
        '10:30': {},
        '10:45': {},
        '11:00': {},
        '11:15': {},
        '11:30': {},
        '11:45': {},
        '12:00': {},
        '12:15': {},
        '12:30': {},
        '12:45': {},
        '13:00': {},
        '13:15': {},
        '13:30': {},
        '13:45': {},
        '14:00': {},
        '14:15': {},
        '14:30': {},
        '14:45': {},
        '15:00': {},
        '15:15': {},
        '15:30': {},
        '15:45': {},
        '16:00': {},
        '16:15': {},
        '16:30': {},
        '16:45': {},
        '17:00': {},
        '17:15': {},
        '17:30': {},
        '17:45': {},
        '18:00': {},
      },
      speechlive: {
        username: '',
        password: '',
      },
      notificationsMuted: false,
      hideNoteInviteGuest: false,
      dailyReportDay: undefined,
      favoriteRooms: [],
    },
    user: {
      organigramText: undefined,
      organisationEditable: false,
      created: undefined,
      name: '',
      originalActivity: 'Available',
      activity: 'Available',
      additionalStatus: '',
      noCallsAdditionalInfo: '',
      rtcCallStatus: '',
      rtcCallStart: 0,
      inBridgeCall: undefined,
      inBridgeCallListener: undefined,
      bridgeCallInfo: undefined,
      isMobile: isMobile(),
      avatar: 'img/default_profile_picture.png',
      avatarRectangle: undefined,
      aiUser: undefined,
      guest: false,
      visitor: false,
      visitorData: false,
      // sections: [],
      // locations: [],
      // teams: [],
      assignedTickets: [],
      assignedTicketsByMe: [],
      assignedArchivedTickets: [],
      assignedArchivedTicketsByMe: [],
      groups: [],
      userGroups: [], // old group structure
      lastTicketNumber: 0,
      description: undefined,
      location: undefined,
      email: '',
      emailAlias: undefined,
      phone: '',
      extension: '',
      mobilePhone: '',
      userUnreadTicketCounter: 0,
      authorUnreadTicketCounter: 0,
      unreadMessageCounter: 0,
      targetGroupId: 0,
      activeGroupMembers: [],
      activeGroupName: '',
      lastViewedCalendar: undefined,
      lastViewedInfoboard: undefined,
      lastPurgedConferences: undefined,
      subordinateActiveUser: '',
      startHolidays: '',
      endHolidays: '',
      startIllness: undefined,
      endIllness: undefined,
      presentFrom: undefined,
      inviters: {},
      requestedPayment: {},
      almostExpiredWarned: null,
      paymentState: {
        attachTo: false
      },
      colorsettings: { wholeBar: true, handSetOnly: false },
      collapsed: {
        section: [],
        department: []
      },
      // invisibleAdmin: false,
      hidedByAdmin: false,
      outsideInvitees: [],
      videoCallInvitations: [],
      representative: undefined,
      representatives: {
        active: false,
        users: []
      },
      individualRelay: false,
      dontShowAvatarMessage: false,
      userSettings: {
        sidebarMini: true,
        showOneCalls: true,
        showWaitingRoom: true,
        showGroups: true,
        showVideoCallPlanner: true,
        outlookCalendarImportURL: '',
        appleCalendarImportURL: '',
        showLocation: true,
        showSpeechLive: false,
        dialUpTime: 30000,
        callDuration: undefined,
        numberNotificationWaitingroom: 2,
        startView: 'my-favorites',
        beacon: false,
        beaconCall: 'waitingCall',
        beaconInCall: 'active',
        enabledBeaconUserSetting: false,
        usersSort: 'statusColor',
        favoritesSort: 'lastName',
        favoritesViewMobile: 'grid',
        organigramUsersSort: 'lastName',
        messagesSort: 'newestAbove', //'newestBelow',
        dynamicSidePanel: false,
        minBeforeBlinkPlanerEvent: 5,
        showStatusNumber: false,
        screensaver: {
          text: Vue.prototype.$t('components.adminSettingsModal.never'),
          value: 'never'
        },
        videoCameraCallOn: false,
        audioCallOn: true,
        showAssistantAppointment: false,
        dingDongTimeForRepeat: '180000',
        educationBackground: '',
        additionalKnowledge: '',
        additionalBriefDesc: '',
        additionalWorkExp: '',
        additionalInterests: '',
        additionalStartDate: '',
        additionalCompanyStartDate: undefined,
      },
      guestDeviceSetted: false,
      mobileLastActive: 0,
      userLocation: 'homeOffice',
      startCallInPresentation: false,
      consents: {
        onCallDisplay: null
      },
      readGridMessage: false,
      speechlive: {
        language: '',
        activated: false
      },
      dasdUserStatus: 'inactive',
      omkUserStatus: 'inactive',
      dasdUserId: '',
      rmoUserId: '',
      enabledBeacon: false,
      beaconNameDE: undefined,
      beaconNameEN: undefined,
      beaconNameES: undefined,
      externalServicesStatus: ["green", "yellow"],
      externalServicesActives: [],
      breakTimeStart: '',
      breakTimeEnd: '',
      intern: undefined,
      waitingRoomAssistants: undefined,
      waitingRoomAssists: undefined,
      countTime: undefined,
      weekCountTimeUUID: undefined,
    },
    streamingSettings: {
      streamUrl: '',
      streamKey: '',
      internalPlayerOnly: false,
      streamLink: '',
      wallpaperSrc: ''
    },
    userStatus: ['Available', 'Busy', 'In meeting', 'Just urgent calls', 'Coffee break', 'Out of Office', 'Only phone', 'No Calls', 'Break', 'Holidays', 'Ill', 'No status', 'Offline'],
    userLocations: ['homeOffice', 'realoOffice', 'outOfHouse', 'notSpecified'],
    showUserModalMultiSelect: false,
    showAddGroupModal: false,
    showAddToGroupModal: false,
    personToAddToGroup: undefined,
    groupSelected: undefined,
    navigationCount: 0,
    group: {},
    incomingCallModal: {
      // show: false,
      calls: {}
    },
    incomingBridgeCallModal: {
      // show: false,
      calls: {}
    },
    FileTransferModal: {
      files: {}
    },
    userTicketsModal: {
      tickets: []
    },
    userCustomStatus: '',
    pingModal: {
      pingsPongs: {}
    },
    positionInWaitingRoom: undefined,
    tmpMessagetext: '',
    showModalMultiSelectNote: {
      uuid: undefined,
      isMultiple: true,
      activeFrom: '',
      activeUntil: '',
      info: {
        selectSection: [],
        selectDepartments: [],
        selectTeams: [],
        selectUsers: []
      }
    },
    showModalSenMsg: undefined,
    showCompSendMsg: undefined,
    userTicketsArray: [],
    userTicketsArchivedArray: [],
    tmpSnapshot: undefined,
    errorWebcam: false,
    showModalNotify: {},
    gitCommit: {
      dirty: false,
      committedOn: 0,
      author: '',
      subject: 'Loading...',
      shortHash: ''
    },
    rtc: {},
    localStreams: {
      user: undefined,
      userCanvas: undefined,
      display: undefined,
      lastMicChange: undefined
    },
    remoteStreams: {},
    remoteBridgeStreams: {},
    errorUserSetup: '',
    ticket: {
      t_Uuid: '',
      t_number: 0,
      t_usernumb: 0,
      t_assignee: '',
      t_assigner: '',
      t_title: '',
      t_tags: [],
      t_description: '',
      t_date: '',
      t_deadline: '',
      t_ready_percent: '',
      t_done: false,
      t_done_on: 0,
      t_viewed: false,
      t_ownerviewed: false,
      t_notes: [],
      t_ismulti: false,
      t_assigneeList: [],
      t_progress_info: []
    },
    ticketfiltering: {
      dateAsc: false,
      dateDesc: true,
      percentAsc: false,
      percentDesc: false,
      status: { id: 'open', name: 'Open' },
      author: { id: 'all', name: 'All' },
      assignee: { id: 'all', name: 'All' },
      milestone: { id: 'all', name: 'All' },
      bystatus: false,
      byauthor: false,
      byassignee: false,
      bymilestone: false
    },
    showGenericTickets: false,
    genericTicketUser: '',
    genericTicketsForUser: [],
    namespaceSettings: {
      newOrganigram: undefined,
      marriageProject: [],
      companyInfo: {
        employees: 1,
        company_name: '',
        comercial_register: '',
        tax_number: '',
        street_address: '',
        email: '',
        city: '',
        state: '',
        phone: '',
        virtualOfficePrefix: '',
        contactName: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        houseNumber: ''
      },
      headerIcon: '', // Different logo at header section #647
      companyLogo: '',
      alias: '',
      excelOverwriteData: false,
      companyHomePage: '',
      customInviteMessage: '',
      customAccessLinkMessage: '',
      customInviteGuestMessage: '',
      customVisitorInviteText: '',
      customVisitorWhatsAppInviteText: '',
      iceServers: [],
      notes: [],
      ticketCounter: {
        total: 0,
        open: 0,
        done: 0,
        ts: 0
      },
      callStatistics: [],
      todaysCalls: {
        total: 0,
        good: 0,
        bad: 0,
        rated: 0,
        rating: 0,
        ts: 0
      },
      inviteLinkExpires: '0',
      noNewUsers: false,
      setupDone: false,
      organisation: [],
      processedOrganisation: {},
      organisationSettings: {
        sections: {},
        departments: {},
        teams: {},
        ts: 0
      },
      creatorInfo: {},
      validSubscription: undefined,
      excelLastUpdate: undefined,
      usersImported: false,
      companyModalText: 'fasse dich kurz!',
      publicTasks: false,
      publicMsgs: false,
      defaultMeetingDuration: { state: Vue.prototype.$t('components.meetingTime.unlimited'), value: 86400000 },
      disabledAllSectionsVisibility: false,
      showAllSectionsVisibility: false,
      featureOrganization: true,
      showHeadlineOrganization: false,
      headlineOrganigram: [
        {
          id: 0,
          de: 'Bereiche',
          en: 'Areas',
          es: 'Areas',
        },
        {
          id: 9999,
          de: 'Teammitglieder',
          en: 'Team Members ',
          es: 'Miembros',
        },
      ],
      showCountQualityStatistics: false,
      disabledSections: [],
      showAllSectionsOrga: [],
      companyTimeStart: '',
      companyTimeEnd: '',
      showCallData: false,
      showBlurEffectOption: true,
      twoFaLifetimeHours: 1,
      waitingRoomHeadline: '',
      waitingRoomDescription: '',
      waitingRoomTimeSentence: '',
      waitingRoomUrl: '',
      waitingRoomAlias: '',
      waitingRoomWelcomeTextDE: 'Herzlich willkommen, Ihre Video-Konferenz startet in Kürze.',
      waitingRoomWelcomeTextEN: 'Welcome, your video conference will start shortly.',
      waitingRoomWelcomeTextES: 'Bienvenido, tu videoconferencia comenzará en breve.',
      labelButtonHomePage: 'HomePage',
      waitingRoomBackground: undefined,
      workersLoungeUrl: '',
      workersLoungeBackground: undefined,
      sectionAlias: undefined,
      departmentAlias: undefined,
      teamAlias: undefined,
      companyLocations: [],
      activeCompanyLocations: [],
      sendIosLink: false,
      locationPrivacity: 'always',
      displayAssistants: false,
      loginDaily: true,
      displayUserStatus: true,
      showNoStatus: false,
      showCoffeeBreak: false,
      showWorkingTime: false,
      showConferencehall: false,
      showDailyReport: false,
      dailyReportValue: 'perUser',
      displayCurrentVideocallForUser: false,
      displayAllUsers: true,
      featureInfoBoard: true,
      displayCurrentVideocallForManagement: true,
      enableLocation: true,
      activate2FAType: 'default',
      activateSMS: false,
      activatePIN: true,
      activatePassword: true,
      userSetTime: false,
      userSetTimeNumber: 0,
      organisationFavorite: 'favorites',
      favoriteSections: [],
      showOrganigrammFor: [],
      basicView: false,
      paySetting: false,
      adminProducts: [],
      payHours: 0,
      totalVats: [],
      currency: 'EUR',
      showRelayLabel: false,
      // dynamicSidePanel: false,
      dingDongTimeForRepeat: '180000',
      decryptError: false,
      enableRemoteStorage: false,
      basicVersion: true,
      remoteStorageData: {
        url: '',
        username: '',
        password: '',
        port: null,
        syncIntervale: 1,
        remoteStorage: 'FTP'
        // remoteStorageModel: 'FTP', // FTP or SFTP
        // remoteStorageAuth: "password", // pasword or keyfile,
        // privateKey: '',
        // keyProtected: false,
        // keyPass: ''
      },
      qualityLogging: true,
      featureFileTranfer: true,
      vofficeNameColor: '#000000',
      adminSettingsEnable2FA: false,
      activateFtp: false,
      pictureSize: 'rectangle',
      timelineSelection: 'timelineObligatory',
      useFeatureTimeline: true,
      legalMailFooter: '',
      // bridgeCallSettings:{
      //   authToken: '',
      //   signalingServer: '',
      //   videoBridgeServer: [],
      //   videoBridgeServerSelected: ''
      // },
      ramicroInUse: undefined,
      ramicroVofCustomerId: undefined,
      ramicroVofExist: null,
      ramicroCustomerHasProduct: undefined,
      ramicroCheckingErrorMessage: undefined,
      defaultWaitingRoomGreeting: Vue.prototype.$t("components.waitingRoom.greetingText"),
      waitingRoomGreeting: undefined,
      waitingRoomExpectedTime: undefined,
      autoDeleteConferences: '0',
      deletionMessages: '3months',
      deletionCalls: '3months',
      deletionInvites: '3months',
      deletionProtocoll: '3months',
      waitingRoomSocialMedia: {
        facebookLink: '',
        instagramLink: '',
        twitterLink: '',
        youtubeLink: '',
        linkedinLink: '',
        webLink: '',
        templateBg: {
          imageName:'',
          imagePath: '',
          imageClass: ''
        },
      },
      workersLoungeSocialMedia: {
        facebookLink: '',
        instagramLink: '',
        twitterLink: '',
        youtubeLink: '',
        linkedinLink: '',
        webLink: '',
        templateBg: {
          imageName:'',
          imagePath: '',
          imageClass: ''
        },
      },
      inviteGuestCustomTexts: [],
      timezone: '',
      beaconTitle: '',
      beaconTitleEN: '',
      beaconTitleES: '',
      noUserMessage: '',
      noUserMessageEN: '',
      noUserMessageES: '',
      beaconColor: '#a51916',
      beaconPosition: {
        'position': 'right',
        'bottomPx': 0,
        'rightPx': 0,
        'leftPx': 0,
      },
      beaconSize: undefined,
      beaconCode: '',
      showDescriptionInBeacon: false,
      descriptionShownInBeacon: null,
      showTeamsInBeacon: false,
      teamsShownInBeacon: null,
      strictRule: false,
      activateE2E: true,
      customTemplateWaitingRoomSelected: undefined,
      eventApiKey: YOUTUBE_API_KEY_DEFAULT,
      eventClientId: YOUTUBE_CLIENT_ID_DEFAULT,
      openAiApiKey: '',
      humanPictureApiKey: '',
      confidentialAiEndpoint: '',
      showGPT4BOT: false,
      useWebSpeech: true,
      showSocialMedia: false,
      dailyReportSettings: {
        sections: {},
        teams: {},
        members: {}
      },
      weeklyTimecountingTemplates: [],
      beaconDomainWhitelist:{
        enabled: false,
        domains: []
      },
      beaconLanguage: 'default'
    },
    setUpConfig: {
      showSetUp: false,
      route: 'subdomainAvailable'
    },
    excelFile: undefined,
    sendInvitationUuid: undefined,
    editExcel: false,
    currentContentVisile: {
      showComponent: false,
      component: ''
    },
    changeCompanyNameModal: false,
    virtualOfficePrefix: undefined,
    showNote: {
      show: false,
      note: undefined,
      inserting: false,
      editnote: false
    },
    // showTaskBar: false,
    activeOrganisationSection: '',
    showAddTeamModal: false,
    showHome: false,
    showModalMeetingTime: undefined,
    durationMeeting: undefined,
    userInConference: undefined,
    meetingStartAt: undefined,
    meetingDialUPTime: undefined,
    ownerMeeting: undefined,
    messageToParticipants: {
      sound: undefined,
      audio: undefined
    },
    showModalEndMeeting: undefined,
    presentationView: {
      show: false,
      owner: undefined,
      fromScreenshare: false,
    },
    excelTotalView: false,
    showModalUploadPicure: true,
    infoModal: {
      show: false,
      header: '',
      body: ''
    },
    modalPopUp: undefined,
    showModalVotingQuality: false,
    showModalActiveUser: false,
    showModalAudioPolicy: false,
    canAutoplayWithSound: false
  },
  setDailyReportDay(value){
    this.state.persisted['dailyReportDay'] = value;
  },
  setCanAutoplayWithSound(value) {
    this.state['canAutoplayWithSound'] = value;
  },
  setExternalServicesStatus(value) {
    this.state.user['externalServicesStatus'] = value;
  },
  setExternalServicesActives(value) {
    this.state.user['externalServicesActives'] = value;
  },
  setCustomInviteUserMessage(value) {
    this.state.persisted['customInviteUserMessage'] = value;
  },
  setCustomInviteGuestMessage(value) {
    this.state.persisted['customInviteGuestMessage'] = value;
  },
  setHideNoteInviteGuest(value){
     this.state.persisted['hideNoteInviteGuest'] = value;
  },
  setStartCallInPresentation(value) {
    this.state.user['startCallInPresentation'] = value;
  },
  setmodalPopUp(value) {
    this.state['modalPopUp'] = value;
  },
  setAlmostExpired(value){
    this.state.user['almostExpiredWarned'] = value;
  },
  setPaymentState(value) {
    this.state.user['paymentState'] = value;
  },
  resetAppointments() {
    this.state.persisted['appointmentDate'] = Date.now();
    Object.keys(this.state.persisted.appointment).forEach((key) => {
      this.setAppointment(key, {});
    });
  },
  setAppointment(key, value) {
    this.state.persisted.appointment[key] = value;
  },
  setinfoModal(value) {
    this.state['infoModal'] = value;
  },
  setRepresentatives(active, users) {
    const value = { active: active, users: users };
    this.state.user['representatives'] = value;
  },
  setWeeklyTimecountingTemplates(value, type) {
    const data = this.state.namespaceSettings.weeklyTimecountingTemplates;
    switch (type) {
      case 'add':
        data.push(value);
        break;
      case 'update': {
        const foundIndex = data.findIndex(x => x._id == value._id);
        if (foundIndex!==-1) {
          data[foundIndex] = value;
        }
        break;
      }
      case 'delete': {
        const foundIndex = data.findIndex(x => x._id == value._id);
        if (foundIndex!==-1) {
          data.splice(foundIndex, 1);
        }
        break;
      }
      default:
        break;
    }
    this.state.namespaceSettings['weeklyTimecountingTemplates'] = data;
  },
  setCustomLocations(value) {
    this.state.namespaceSettings['companyLocations'] = value;
  },
  setLegalMailFooter(value) {
    this.state.namespaceSettings['legalMailFooter'] = value;
  },
  setColorSettings(wholebar, handset) {
    const value = { handSetOnly: handset, wholeBar: wholebar };
    this.state.user['colorsettings'] = value;
  },
  async setSaveHolidays(startDate, endDate, uuid, type = 'Holidays') {
    const delay = uuid === store.state.ownUUID
      ? syncedUserState()
      : syncedGroupState();
    await delay.catch((err) => console.warn('syncedState setSaveHolidays Err:', err, startDate, endDate, uuid, type));
    if (uuid === store.state.ownUUID) {
      switch (type) {
        case 'Holidays': {
          this.state.user['startHolidays'] = startDate;
          this.state.user['endHolidays'] = endDate;
          if (startDate <= this.state.user['startIllness'] && endDate >= this.state.user['startIllness']) {
            this.state.user['startIllness'] = undefined;
            this.state.user['endIllness'] = undefined;
          }
        } return;
        case 'Absence':
        case 'Ill': {
          this.state.user['startIllness'] = startDate;
          this.state.user['endIllness'] = endDate;
          if (
            // It is desired that if a holidays status is set, any subsequent absence status should end the holidays status.
            (startDate <= this.state.user['startHolidays'] && endDate >= this.state.user['startHolidays']) ||
            // Check if the event is within the same time span
            (startDate >= this.state.user['startHolidays'] && startDate < this.state.user['endHolidays']) ||
            (endDate > this.state.user['startHolidays'] && endDate <= this.state.user['endHolidays']) ||
            (startDate <= this.state.user['startHolidays'] && endDate >= this.state.user['endHolidays'])
          ) {
            this.state.user['startHolidays'] = undefined;
            this.state.user['endHolidays'] = undefined;
          }
        } return;
      }
    }
    wsCall('setSaveHolidays', startDate, endDate, uuid, type);
  },
  async setRepresentative(representative, uuid) {
    const delay = uuid === store.state.ownUUID
      ? syncedUserState()
      : syncedGroupState();
    await delay.catch((err) => console.warn('syncedState setRepresentative Err:', err, representative, uuid));
    if (uuid === store.state.ownUUID) {
      this.state.user['representative'] = representative;
      return;
    }
    wsCall('setRepresentative', representative, uuid);
  },
  async setSaveHolidaysRepresentative(startDate, endDate, representative, uuid, type) {
    const delay = uuid === store.state.ownUUID
      ? syncedUserState()
      : syncedGroupState();
    await delay.catch((err) => console.warn('syncedState setSaveHolidaysRepresentative Err:', err, startDate, endDate, representative, uuid));
    if (uuid === store.state.ownUUID) {
      await store.setSaveHolidays(startDate, endDate, uuid, type);
      await store.setRepresentative(representative, uuid);
      return;
    }
    wsCall('setSaveHolidaysRepresentative', startDate, endDate, representative, uuid, type);
  },
  setActivityUser(status) {
    this.state.user['activity'] = status;
  },
  setBreakTime(start, end) {
    if (!end) end = start;
    this.state.user['breakTimeStart'] = end === start ? '' : start;
    this.state.user['breakTimeEnd'] = end;
  },
  setReadGridMessage(value) {
    this.state.user['readGridMessage'] = value;
  },
  setDasdUserStatus(value) {
    this.state.user['dasdUserStatus'] = value;
  },
  setOmkUserStatus(value) {
    this.state.user['omkUserStatus'] = value;
  },
  setAdditionalStatusUser(additionalStatus) {
    this.state.user['additionalStatus'] = additionalStatus;
  },
  setNoCallsAdditionalInfo(additionalStatusInfo) {
    this.state.user['noCallsAdditionalInfo'] = additionalStatusInfo;
  },
  setShowModalUploadPicure(value) {
    this.state['showModalUploadPicure'] = value;
  },
  setExcelTotalView(value) {
    this.state['excelTotalView'] = value;
  },
  setActiveOrganisationSection(section) {
    this.state['activeOrganisationSection'] = section;
  },
  setusersCustomAppointmentEntries(value) {
    this.state.persisted['usersCustomAppointmentEntries'] = value;
  },
  setShowHomeView(status) {
    this.state['showHome'] = status;
  },
  // setShowTaskBar(status) {
  //   this.state['showTaskBar'] = status;
  // },
  setUseBlurEffect(newVal) {
    this.state.persisted['useBlurEffect'] = newVal;
  },
  setUserCanvas(stream) {
    this.state.localStreams['userCanvas'] = stream;
  },
  setSearchTerm(searchTerm) {
    this.state['searchTerm'] = searchTerm;
  },
  getVideoSettings() {
    return this.state.persisted.rtcSettings || {};
  },
  setChangeCompanyNameModal(showHiden) {
    this.state['changeCompanyNameModal'] = showHiden;
  },
  setShowNote(showHiden, note, inserting = false, editnote = false) {
    const data = {
      show: showHiden,
      note: note,
      inserting: inserting,
      editnote: editnote
    };
    this.state['showNote'] = data;
  },
  setUserSpeechlive(data) {
    const { username, password, language, activated } = data;
    this.state.user['speechlive'] = { language, activated };
    this.state.persisted['speechlive'] = { username, password };
  },
  setUserSettings(data) {
    this.state.user['userSettings'] = data;
  },
  setOrganisationEditableView(value){
    this.state.user['organisationEditable'] = value;
  },
  setUserStreamData(data) {
    this.state.user['streamingSettings'] = data;
  },
  setSubordinateUser(value) {
    this.state.user['subordinateActiveUser'] = value;
  },
  setPresentFrom(value) {
    this.state.user['presentFrom'] = value;
  },
  setLastConfPurged(value){
    this.state.user['lastPurgedConferences'] = value;
  },
  setUserName(value){
    this.state.user['name'] = value;
  },
  setUserLocation(value){
    this.state.user['location'] = value;
  },
  setUserDescription(value){
    this.state.user['description'] = value;
  },
  // setInvisibleAdmin (value) {
  //   this.state.user['invisibleAdmin'] = value;
  // },
  setCurrentContentVisile(content, showComponent, $router = null, exactRoute = null) {
    let promise = Promise.resolve();
    if (content === 'infoboard') {
      this.state.user['lastViewedInfoboard'] = Date.now();
    }
    if (content === '/calendar') {
      this.state.user['lastViewedCalendar'] = Date.now();
    }
    const data = {
      showComponent: showComponent,
      component: content
    };
    const route = (exactRoute || content || 'home').replace(/^\//g, '');
    if (typeof $router === 'object' && $router?.currentRoute?._value?.path && $router?.currentRoute?._value?.path !== `/${route}`) {
      promise = $router.push({ path: `/${route}` });
      this.state.navigationCount++;
    }
    this.state['currentContentVisile'] = data;
    return promise;
  },
  toggleRtcVideo() {
    this.state.persisted['rtcVideo'] = !this.state.persisted.rtcVideo;
  },
  toggleRtcAudio() {
    this.state.persisted['rtcAudio'] = !this.state.persisted.rtcAudio;
  },
  getRtcVideo() {
    if (!this.state.persisted.rtcVideo) return false;
    if (this.state.persisted.mediaDeviceSetup.videoDeviceId) return { deviceId: this.state.persisted.mediaDeviceSetup.videoDeviceId };
    return {};
  },
  getRtcVideoQuality() {
    return this.state.persisted.rtcVideoQuality;
  },
  getRtcAudio() {
    if (!this.state.persisted.rtcAudio) return false;
    if (this.state.persisted.mediaDeviceSetup.audioDeviceId) return { deviceId: this.state.persisted.mediaDeviceSetup.audioDeviceId };
    return {};
  },
  setNamespaceSettingsFromSig(settings) {
    //  console.log('setNamespaceSettingsFromSig', settings);
    this.state['namespaceSettings'] = { ...this.state.namespaceSettings, ...(settings || {}) };
  },
  setExcelFile(file) {
    this.state['excelFile'] = file;
  },
  setShowSetup(show) {
    const data = {
      showSetUp: show,
      route: 'subdomainAvailable'
    };
    this.state['setUpConfig'] = data;
  },
  setSetUpConfigRoute(route) {
    const data = {
      showSetUp: true,
      route: route
    };
    this.state['setUpConfig'] = data;
  },
  updateTS() {
    this.state['currentTS'] = Date.now();
  },
  setIsDark(val) {
    this.state.persisted['isDark'] = val;
  },
  setShowSidebar(val) {
    this.state.persisted['showSidebar'] = val;
  },
  setShowMessageInMeetingTimeModal(val) {
    this.state.persisted.tmpMessages['showMessageInMeetingTimeModal'] = val;
  },
  setShowMessageInitiateCallSpace(val) {
    this.state.persisted.tmpMessages['showMessageInitiateCallSpace'] = val;
  },
  setShowMessageUpdateProVersionInConference(val) {
    this.state.persisted.tmpMessages['showMessageUpdateProVersionInConference'] = val;
  },
  setShowInfoIncomingCallModal(val) {
    this.state.persisted.tmpMessages['showInfoIncomingCallModal'] = val;
  },
  setVirtualOfficePrefix(val) {
    this.state['virtualOfficePrefix'] = val;
  },
  getVirtualOfficePrefix() {
    const domainComponents = window.location.hostname.split('.');
    if (domainComponents.length >= 3) {
      const firstComponent = domainComponents[0];
      if (firstComponent) {
        const subdomainText = firstComponent.replace(/_\d+$/g, '');
        return subdomainText + ' ';
      }
    }
    return '';
  },
  getPersonByUuid(uuid) {
    const person = this.state.group[uuid];
    if (!person || !hasOwn.call(person, 'user')) return person;
    return { uuid: person.uuid || person.user?.uuid, ...person };
  },
  getTicketsByUuid(uuid) {
    return this.state.group[uuid].assignedTickets;
  },
  getTicketsAssingnedByMe(uuid) {
    return this.state.group[uuid].assignedTicketsByMe;
  },
  setAuthorUnreadTicketCounter(value) {
    this.state.user['authorUnreadTicketCounter'] = value;
  },
  setUserUnreadTicketCounter(value) {
    this.state.user['userUnreadTicketCounter'] = value;
  },
  setUserTicketsArrayIdx(idx, value) {
    this.state.userTicketsArray[idx] = value;
  },
  // setUserActiveTicket (info) {
  //   if(this.state.activeTicket.t_Uuid === info.t_Uuid){
  //     this.state['activeTicket'] = info;
  //   }
  // },
  getUuidFromFavorites(uuid) {
    return this.state.persisted.favoriteUsers[uuid];
  },
  getUuidFromGuests(uuid) {
    return (this.state.persisted.invitedUsers[uuid] || ((this.state.group[uuid].user) ? this.state.group[uuid].user.visitor : false));
  },
  getUserCanCreateGroups(uuid) {
    return !(this.state.group[uuid].permissions === 4 || this.state.group[uuid].permissions === 5);
  },
  getUserCanInviteGuests(uuid) {
    return !(this.state.group[uuid].permissions === 3 || this.state.group[uuid].permissions === 5);
  },
  getUserIsAdmin(uuid) {
    if (this.state.group[uuid] && this.state.group[uuid].permissions === 1) {
      return true;
    } else {
      return false;
    }
  },
  getAdminList() {
    const list = [];
    for (const uuid in this.state.group) {
      if (hasOwn.call(this.state.group, uuid)) {
        const element = this.state.group[uuid];
        if (element.permissions === 1) {
          list.push({ ...element, uuid });
        }
      }
    }
    return list;
  },
  getWaitingRoomList(uuidUser = this.state.ownUUID) {
    const list = [];
    for (const uuid in this.state.group) {
      if (hasOwn.call(this.state.group, uuid)) {
        const element = this.state.group[uuid];
        if (element.user && element.user.visitorData && element.user.visitorData.isWaitingRoom && element.user.visitorData.userInviter === uuidUser && element.connected) {
          list.push({ ...element, uuid });
        }
      }
    }
    return list;
  },
  getAssistantGuest(uuidUser = this.state.ownUUID) {
    const list = [];
    for (const uuid in this.state.group) {
      if (hasOwn.call(this.state.group, uuid)) {
        const element = this.state.group[uuid];
        if (element.user && element.user.visitorData && element.user.visitorData.isWaitingRoom && element.user.visitorData.userInviter === uuidUser) {
          list.push({ ...element, uuid });
        }
      }
    }
    return list;
  },
  getUserIsGuest(uuid) {
    if (uuid === this.state.ownUUID && this.state.user && (this.state.user.guest || this.state.user.visitor)) {
      return true;
    } else if (this.state.group[uuid] && !this.state.group[uuid].externalCall && (this.state.group[uuid].permissions === 10 || (this.state.group[uuid].user || {}).visitor || (this.state.group[uuid].user || {}).guest)) {
      return true;
    } else {
      return false;
    }
  },
  getFavoriteRooms() {
    return this.state.persisted.favoriteRooms || [];
  },
  setFavoriteRooms(favoriteRooms) {
    this.state.persisted['favoriteRooms'] = favoriteRooms;
  },
  setUserVisitor(visitorId, visitorData) {
    (visitorData === undefined)
      ? delete this.state.persisted.userVisitors[visitorId]
      : this.state.persisted.userVisitors[visitorId] = visitorData;
  },
  setUuidInFavorites(uuid, info) {
    (info === undefined)
      ? delete this.state.persisted.favoriteUsers[uuid]
      : this.state.persisted.favoriteUsers[uuid] = info;
  },
  setUuidInInvited(uuid, info) {
    (info === undefined)
      ? delete this.state.persisted.invitedUsers[uuid]
      : this.state.persisted.invitedUsers[uuid] = info;
  },
  setEndCallDateVisitor() {
    this.state.user.visitorData['userLastCallTs'] = Date.now();
  },
  setLastInteractionWithUuid(uuid, ts) {
    if (!ts) ts = Date.now();
    // console.log('setLastInteractionWithUuid', uuid, ts);
    this.state.persisted.interactionHistory[uuid] = ts;
  },
  getLastInteractionWithUuid(uuid) {
    return this.state.persisted.interactionHistory[uuid] || 0;
  },
  setIncomingCallFor(uuid, info) {
    // console.log('setIncomingCallFor', uuid, info);
    this.state.incomingCallModal.calls[uuid] = info;
  },
  setIncomingBridgeCallFor(uuid, info) {
    (info === undefined)
      ? delete this.state.incomingBridgeCallModal.calls[uuid]
      : this.state.incomingBridgeCallModal.calls[uuid] = info;
  },
  setFileTransferFor(uuid, fileName, info) {
    // console.log('setFileTransferFor', uuid, fileName, info);
    this.state.FileTransferModal.files[uuid + '_' + fileName] = info;
  },
  getFileTransferFor(uuid, fileName) {
    // console.log('setFileTransferFor', uuid, fileName);
    return this.state.FileTransferModal.files[uuid + '_' + fileName] || {};
  },
  patchFileTransferFor(uuid, fileName, patch) {
    this.state.FileTransferModal.files[uuid + '_' + fileName] = { ...this.getFileTransferFor(uuid, fileName), ...patch };
  },
  delFileTransferFor(uuid, fileName) {
    delete this.state.FileTransferModal.files[uuid + '_' + fileName];
  },
  removeAcceptedCallNotification(uuid) {
    if (((this.state.persisted.userMessages || {}).messages || {})[uuid]) {
      let mostRecentDate = 0;
      let mostRecentNoti = '';
      for (const i in this.state.persisted.userMessages.messages[uuid]) {
        if (this.state.persisted.userMessages.messages[uuid][i].info && this.state.persisted.userMessages.messages[uuid][i].info.notificationType === 'call') {
          if (this.state.persisted.userMessages.messages[uuid][i].date > mostRecentDate) {
            mostRecentDate = this.state.persisted.userMessages.messages[uuid][i].date;
            mostRecentNoti = i;
          }
        }
      }
      if (mostRecentDate && mostRecentNoti) {
        this.removeMessage(uuid, mostRecentNoti);
        if (this.state.persisted.totalNotification > 0) this.settotalNotification(--this.state.persisted.totalNotification);
        if (this.state.persisted.totalNotificationCall > 0) this.setTotalNotificationCall(--this.state.persisted.totalNotificationCall);
      }
    }
  },
  appendGroupCallHistory(groupLog) {
    if (!Array.isArray(this.state.persisted.callHistory.groupCalls)) {
      this.state.persisted.callHistory['groupCalls'] = [];
    } else if (this.state.persisted.callHistory.groupCalls.length > 25) {
      const aDayAgo = Date.now() - 86400000; // 1d
      this.state.persisted.callHistory['groupCalls'] = this.state.persisted.callHistory.groupCalls.slice(-24).filter((e) => e.endTs > aDayAgo);
    }
    this.state.persisted.callHistory.groupCalls.push(groupLog);
  },
  appendIncomingCallHistory(uuid, callUuid, ts) {
    if (!Array.isArray(this.state.persisted.callHistory.incoming)) {
      this.state.persisted.callHistory['incoming'] = [];
    } else if (this.state.persisted.callHistory.incoming.length > 50) {
      const aDayAgo = Date.now() - 86400000; // 1d
      this.state.persisted.callHistory['incoming'] = this.state.persisted.callHistory.incoming.slice(-49).filter((e) => e.ts > aDayAgo);
    }
    return this.state.persisted.callHistory.incoming.push({
      uuid,
      callUuid,
      ts: ts || Date.now()
    });
  },
  appendOutgoingCallHistory(uuid, callUuid, ts) {
    if (!Array.isArray(this.state.persisted.callHistory.outgoing)) {
      this.state.persisted.callHistory['outgoing'] = [];
    } else if (this.state.persisted.callHistory.outgoing.length > 50) {
      const aDayAgo = Date.now() - 86400000; // 1d
      this.state.persisted.callHistory['outgoing'] = this.state.persisted.callHistory.outgoing.slice(-49).filter((e) => e.ts > aDayAgo);
    }
    return this.state.persisted.callHistory.outgoing.push({
      uuid,
      callUuid,
      ts: ts || Date.now()
    });
  },
  setPingPongs(uuid, info) {
    this.state.pingModal.pingsPongs[uuid] = info;
    // store.setLastInteractionWithUuid(uuid);
  },
  setPositionInWaitingRoom(info) {
    this.state['positionInWaitingRoom'] = info;
    // store.setLastInteractionWithUuid(uuid);
  },
  setShowModalNotify(uuid, info) {
    this.state.showModalNotify[uuid] = info;
  },
  setMessageAsRead(uuid, message) {
    if ((message.info || {}).notificationType === 'message' && this.state.user.unreadMessageCounter > 0) this.state.user.unreadMessageCounter--;
    this.state.persisted.userMessages.messages[message.userUUID][uuid] = { ...message, read: true };
  },
  setMessageFor(uuid, message) {
    const uuuid = uuidv4();
    const info = {
      [uuuid]: {
        read: false,
        uuidMessage: uuuid,
        info: message,
        date: Date.now()
      }
    };
    if (!this.state.persisted.userMessages.messages[uuid]) {
      this.state.persisted.userMessages.messages[uuid] = info;
    } else {
      this.state.persisted.userMessages.messages[uuid][uuuid] = { info: message, date: Date.now(), uuidMessage: uuuid, read: false };
    }
    if (message.playSound && !this.state.persisted.notificationsMuted) {
      try {
        const audioPath = '/media/notificationSound.mp3';
        // Get real devices id's
        const ringingDeviceId = this.state.persisted.mediaDeviceSetup.ringingOutputId;
        const audioDeviceId = this.state.persisted.mediaDeviceSetup.audioOutputId;
        // Pre-Set outputs
        const ringingOutput = new Audio(audioPath);
        const audioOutput = new Audio(audioPath);
        // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
        let promise = Promise.resolve();
        if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
          promise = ringingOutput.setSinkId(ringingDeviceId);
          promise
            .then(() => ringingOutput.play())
            .catch(err => console.warn('store(setMessageFor) Failed to play notification audio on ringingOutput', err));
        }
        // Sync audioDevice
        if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
          promise = audioOutput.setSinkId(audioDeviceId)
            .catch(err => console.warn('store(setMessageFor) Failed to play notification audio on audioOutput', err));
        }
        promise
          .then(() => audioOutput.play())
          .catch(err => console.warn('store(setMessageFor) Failed to play notification audio on audioOutput', err));
      } catch (error) {
        console.warn('store(setMessageFor) Failed to play notification audio', error);
      }
    }

    if (message.info) PageTitleNotification.on(message.info);

    switch (message.notificationType) {
      case 'call':
        this.setTotalNotificationCall(++this.state.persisted.totalNotificationCall);
        break;
      case 'message':
        this.setTotalNotificationMessage(++this.state.persisted.totalNotificationMessage);
        break;
      case 'ticket':
        this.setTotalNotificationTicket(++this.state.persisted.totalNotificationTicket);
        break;
      case 'file':
        this.setTotalNotificationFile(++this.state.persisted.totalNotificationFile);
        break;
    }

    this.settotalNotification(++this.state.persisted.totalNotification);
  },
  resettmpMessagetext(newValue) {
    this.state['tmpMessagetext'] = newValue.trim();
  },
  removeMessage(uuid, messageUuid) {
    delete this.state.persisted.userMessages.messages[uuid][messageUuid];
  },
  settotalNotification(amount) {
    this.state.persisted['totalNotification'] = amount;
  },
  setTotalNotificationCall(amount) {
    this.state.persisted['totalNotificationCall'] = amount;
  },
  setTotalNotificationTicket(amount) {
    this.state.persisted['totalNotificationTicket'] = amount;
  },
  setTotalNotificationFile(amount) {
    this.state.persisted['totalNotificationFile'] = amount;
  },
  setTotalNotificationInvite(amount) {
    this.state.persisted['totalNotificationInvite'] = amount;
  },
  setTotalNotificationMessage(amount) {
    this.state.persisted['totalNotificationMessage'] = amount;
    this.state.user['unreadMessageCounter'] = amount;
  },
  settmpSnapshot(b64) {
    this.state['tmpSnapshot'] = b64;
  },
  setOnCallDisplayConsent(val) {
    this.state.user.consents['onCallDisplay'] = val;
  },
  setUserOneCallTariff(val) {
    // console.log('Setting tariffs  ', val);
    const allTariffs = this.state.persisted.userOneCallTariffs;
    if (allTariffs.indexOf(val) === -1) {
      allTariffs.push(val);
      this.state.persisted['userOneCallTariffs'] = allTariffs;
    }
  },
  setErrorWebcam(val) {
    this.state['errorWebcam'] = val;
  },
  setShowCompSendMessage(uuid, type) {
    // console.log('message TYPE ', type);
    const data = {
      uuid: uuid,
      type: type,
      options: (type && type.options) || '',
      recipients: (type && type.recipients) || '',
      team: (type && type.team) || '',
      isAllMembers: type && type.isAllMembers
      // isMultiple: isMultiple
    };
    this.state['showCompSendMsg'] = data;
  },
  setShowModalSenMsg(uuid, type) {
    const data = {
      uuid: uuid,
      type: type,
      options: (type && type.options) || '',
      recipients: (type && type.recipients) || '',
      team: (type && type.team) || '',
      isAllMembers: type && type.isAllMembers
      // isMultiple: isMultiple
    };
    this.state['showModalSenMsg'] = data;
  },
  setShowModalMeetingTime(uuid) {
    this.state['showModalMeetingTime'] = uuid;
  },
  setshowModalEndMeeting(value) {
    this.state['showModalEndMeeting'] = value;
  },
  setshowModalVotingQuality(value) {
    this.state['showModalVotingQuality'] = value;
  },
  setShowModalActiveUser(value) {
    this.state['showModalActiveUser'] = value;
  },
  setShowModalAudioPolicy(value) {
    this.state['showModalAudioPolicy'] = value;
  },
  setUserInConference(value){
    this.state['userInConference'] = value;
  },
  setdurationMeeting(time) {
    this.state['durationMeeting'] = time;
  },
  setRemotePresentationFullSize(isRemoteFull) {
    this.state['remoteFullSize'] = isRemoteFull;
  },
  setRoomInformation(remoteBridgeStream) {
    if (remoteBridgeStream && typeof remoteBridgeStream === 'object') {
      const { roomId, roomGuid, staticRoom } = remoteBridgeStream;
      if (roomId && roomGuid && staticRoom) {
        return this.state['wasInARoom'] = { roomId, roomGuid, roomName: staticRoom };
      }
    }
    return this.state['wasInARoom'] = null;
  },
  setSpeakerViewRequests(speakerRequests) {
    this.state['speakerRequests'] = speakerRequests;
  },
  setRemotePresentationFullSizeUUID(uuid) {
    this.state['remoteToFullSizeUUID'] = uuid;
  },
  // setRemotePresentationFullSizeObject(obj) {
  //   this.state['remoteToFullSizeObject'] = obj;
  // },
  setMessageToParticipants(info) {
    this.state['messageToParticipants'] = info;
  },
  setmeetingStartAt(time) {
    this.state['meetingStartAt'] = time;
  },
  setMeetingDialUPTime(time) {
    this.state['meetingDialUPTime'] = time;
  },
  setOwnerMeeting(value) {
    this.state['ownerMeeting'] = value;
  },
  setPresentationView(value, owner, fromScreenshare = false) {
    const obj = {
      show: value,
      owner: owner,
      fromScreenshare: fromScreenshare
    };
    this.state['presentationView'] = obj;
  },

  setOutsideInvitees(data) {
    this.state.user['outsideInvitees'] = data;
  },

  setVideoCallInvitations(data) {
    this.state.user['videoCallInvitations'] = data;
  },

  setshowModalMultiSelectNote(uuid, isMultiple) {
    const data = {
      uuid: uuid,
      isMultiple: isMultiple
    };
    this.state['setshowModalMultiSelectNote'] = data;
  },
  closeModalMultiSelectNote() {
    this.state['showModalMultiSelectNote'] = undefined;
  },
  closeUserCompMsg() {
    this.state['showCompSendMsg'] = undefined;
  },
  closeModalMsg() {
    this.state['showModalSenMsg'] = undefined;
  },
  setShowModalSendInvitation(uuid) {
    this.state['sendInvitationUuid'] = uuid;
  },
  hideModalSendGuestInvitation() {
    this.state['sendInvitationGuestModal'] = { newInvitation: false, invitationData: {} };
  },
  setGitCommit(newValue) {
    const dirty = this.state.gitCommit.committedOn !== 0 && this.state.gitCommit.shortHash !== newValue.shortHash;
    this.state['gitCommit'] = {
      dirty,
      committedOn: newValue.committedOn,
      subject: newValue.subject,
      shortHash: newValue.shortHash,
      author: newValue.author.name
    };
  },
  setWsOpen(newValue) {
    this.state['wsOpen'] = newValue;
  },
  setWsStatus(newValue) {
    this.state['wsStatus'] = newValue;
  },
  setGroup(newValue) {
    this.state['group'] = newValue;
    return (newValue = this.state['group']);
  },
  setInGroup(uuid, newValue) {
    // this.state.group[uuid] = newValue;
    if (newValue === null || newValue === undefined) {
      delete this.state.group[uuid];
      return;
    }
    this.state.group[uuid] = newValue;
    if (uuid && (this.state.persisted.userVisitors || {})[uuid] && ((newValue || {}).user || {}).visitorData) {
      if (JSON.stringify(this.state.persisted.userVisitors[uuid]) !== JSON.stringify(newValue.user.visitorData)) {
        const visitorData = { ...this.state.persisted.userVisitors[uuid], ...newValue.user.visitorData };
        this.setUserVisitor(uuid, visitorData);
      }
    }
    return (newValue = this.state.group[uuid]);
  },
  setRtcCallStatus(status, ts) {
    this.state.user['rtcCallStatus'] = status;
    this.state.user['rtcCallStart'] = ts;
  },
  setlastPurgeDate(date) {
    this.state.persisted['lastPurgeDate'] = date;
  },
  getUuidForEmail(email, includeGuest = false) {
    const elem = Object.entries(this.state.group).find(
      ([uuid, user]) => user && user.user && user.user.email === email && (includeGuest || !this.getUserIsGuest(uuid))
    );
    return (elem && elem[0]) || undefined;
  },
  getNameForUuid(uuid) {
    if (uuid === this.state.ownUUID && this.state.user && this.state.user.name) {
      return this.state.user.name;
    } else {
      return ((this.state.group[uuid] || {}).user || {}).name || uuid;
    }
  },
  getOrganigramTextForUuid(uuid) {
    return ((this.state.group[uuid] || {}).user || {}).organigramText || '';
  },
  getTitelForUuid(uuid) {
    let myTitle = ((this.state.group[uuid] || {}).user || {}).titel || '';
    if (myTitle === 'unknown') {
      myTitle = '';
    }
    return myTitle;
  },
  getVisitorFunctionForUuid( uuid ){
    let myFunction = (((this.state.group[uuid] || {}).user || {}).visitorData || {}).function || '';
    return myFunction;
  },
  getEmailForUuid(uuid) {
    return ((this.state.group[uuid] || {}).user || {}).email || uuid;
  },
  getPositionForUuid(uuid) {
    return ((this.state.group[uuid] || {}).user || {}).position || "";
  },
  /*
    Dummy users are only created as sections at the organigram (#605),
    we can know if it's dummy because they don't have email.
  */
  getAvatarForUuid(uuid) {
    const { avatar, email } = (this.state.group[uuid] || {}).user || {};
    return ((email && email !== '') || store.getUserIsGuest(uuid))
      ? (avatar || 'img/default_profile_picture.png')
      : avatar !== 'img/default_profile_picture.png'
        ? (avatar || 'img/default_gray_square.png')
        : 'img/default_gray_square.png';
  },
  getAvatarRectangleForUuid(uuid) {
    const { avatar, avatarRectangle } = (this.state.group[uuid] || {}).user || {};
    return avatarRectangle || avatar || 'img/default_profile_picture.png';
  },
  getMessageNotification(uuid, messageUuid) {
    if (!this.state.persisted || !this.state.persisted.userMessages ||
      !this.state.persisted.userMessages.messages ||
      !this.state.persisted.userMessages.messages[uuid] ||
      !this.state.persisted.userMessages.messages[uuid][messageUuid] ||
      !this.state.persisted.userMessages.messages[uuid][messageUuid].info ||
      !this.state.persisted.userMessages.messages[uuid][messageUuid].info.info) {
      return;
    }
    return this.state.persisted.userMessages.messages[uuid][messageUuid].info.info;
  },
  getActivityForUuid(uuid) {
    return this.state.group[uuid] ? this.state.group[uuid].user.activity : 'Literally nothing';
  },
  getrtcCallStatusForUuid(uuid) {
    return this.state.group[uuid] ? this.state.group[uuid].user.rtcCallStatus : '';
  },
  getUserRepresentativeName(userId) {
    const representativeId = (((this.state.group[userId] || {}).user || {}).representative || {}).value || '';
    if (representativeId !== '') {
      let output = "";
      let title = this.getTitelForUuid(representativeId);
      if (title !== '') {
        output = title + ' ';
      }
      output += this.getNameForUuid(representativeId);
      return output;
    }
    return "";
  },
  setAvatar(newAvatar, uuid) {
    const contentType = newAvatar.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setAvatar', newAvatar, uuid);
  },
  setAvatarRectangle(newAvatar, uuid) {
    const contentType = newAvatar.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setAvatarRectangle', newAvatar, uuid);
  },
  setHeaderIcon(newAvatar) {
    const contentType = newAvatar.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setHeaderIcon', newAvatar);
  },
  setCompanyLogo(newAvatar) {
    const contentType = newAvatar.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setCompanyLogo', newAvatar);
  },
  setBeaconLogo(newAvatar) {
    const contentType = newAvatar.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setBeaconLogo', newAvatar);
  },
  setWaitingRoomBackgroundPdf(newPdf) {
    const contentType = newPdf.split(';')[0].split('data:')[1];
    if (contentType !== 'application/pdf') return;
    wsCall('setWaitingRoomBackgroundPdf', newPdf);
  },
  setWaitingRoomBackground(newBackground) {
    const contentType = newBackground.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setWaitingRoomBackground', newBackground);
  },
  setWorkersLoungeBackgroundPdf(newPdf) {
    const contentType = newPdf.split(';')[0].split('data:')[1];
    if (contentType !== 'application/pdf') return;
    wsCall('setWorkersLoungeBackgroundPdf', newPdf);
  },
  setWorkersLoungeBackground(newBackground) {
    const contentType = newBackground.split(';')[0].split('data:')[1];
    if (contentType.split('/')[0] !== 'image') return;
    const ext = contentType.split('/')[1];
    if (!ext || !['png', 'jpeg', 'jpg', 'jpe', 'jfif', 'pjpeg', 'webp', 'gif'].includes(ext)) return;
    wsCall('setWorkersLoungeBackground', newBackground);
  },
  deleteGroup(groupId) {
    this.state.user['userGroups'] = this.state.user.userGroups.filter(e => e.id !== groupId);
  },
  deleteUsersFromGroups(uuids) {
    for (const idx in this.state.user.userGroups) {
      const userGroup = this.state.user.userGroups[idx];
      const toDelete = userGroup.members.findIndex(elem => (!elem || uuids.includes(elem.uuid))) !== -1;
      if (toDelete) {
        this.state.user.userGroups[idx] = {
          ...userGroup,
          members: userGroup.members.filter(elem => (elem && !uuids.includes(elem.uuid))),
        }
      }
    }
  },
  setErrorUserSetup(error) {
    this.state['errorUserSetup'] = error;
  },
  closeUserMedia() {
    // console.trace('closeUserMedia', this.state.localStreams.user);
    closeUserMedia();
    // if (!this.state.localStreams.user) return;
    // removeStreamToAllConnections(this.state.localStreams.user);
    // this.state.localStreams.user.getTracks().forEach(function (track) {
    //   track.stop();
    // });
    // this.state.localStreams['user'] = undefined;
  },
  closeDisplayMedia() {
    closeDisplayMedia();
    // if (!this.state.localStreams.display) return;
    // removeStreamToAllConnections(this.state.localStreams.display);
    // this.state.localStreams.display.getTracks().forEach(function (track) {
    //   track.stop();
    // });
    // this.state.localStreams['display'] = undefined;
  },
  closeLocalStreams() {
    this.closeUserMedia();
    this.closeDisplayMedia();
  },
  addRemoteBridgeStream(callUUID, bridgeCallInfo) {
    if (!bridgeCallInfo) {
      console.error("store(addRemoteBridgeStream) missing bridge call info");
      return false;
    }
    if (this.setRemoteBridgeStream(callUUID, bridgeCallInfo)) {
      if (bridgeCallInfo.inBridgeCallListener) {
        this.state.user['inBridgeCallListener'] = callUUID;
      } else {
        this.state.user['inBridgeCall'] = callUUID;
      }
      return true;
    }
    console.error("store(addRemoteBridgeStream) call uuid mismatch", { key: Object.keys(this.state.remoteBridgeStreams)[0], id: callUUID });
    return false;
  },
  changeIsNormalModeBridgeStream(callUUID, value) {
    if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
      this.state.remoteBridgeStreams[callUUID]['isNormalMode'] = value;
    }
  },
  updateConferenceAttendees(callUUID, value) {
    if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
      this.state.remoteBridgeStreams[callUUID]['conferenceAttendees'] = value;
    }
  },
  changeCallDurationMsBridgeStream(callUUID, value) {
    if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
      this.state.remoteBridgeStreams[callUUID]['callDurationMs'] = value;
    }
  },
  removeInBridgeCall() {
    if (this.state.user.inBridgeCall) {
      if (this.state.user.bridgeCallInfo && this.state.user.bridgeCallInfo.confId) {
        EventBus.$emit("leavingConference", { callId: this.state.user.inBridgeCall, conferenceId: this.state.user.bridgeCallInfo.confId });
      }
      if (this.state.user.originalActivity === 'inCall' || this.state.user.originalActivity === 'inRoom') this.state.user.originalActivity = 'Available';
      if (this.state.user.originalActivity) this.setActivityUser(this.state.user.originalActivity);
    } else {
      EventBus.$emit("rejoiningSession");
    }
    this.state.user['inBridgeCall'] = false;
    this.state.user['inBridgeCallListener'] = false;
    this.state.user['bridgeCallInfo'] = false;
  },
  setInBridgeCallListener(value){
    this.state.user['inBridgeCallListener'] = value;
  },
  setInBridgeCall(value){
    this.state.user['inBridgeCall'] = value;
  },
  setRemoteBridgeStream(callUUID, bridgeCallInfo) {
    if (Object.keys(this.state.remoteBridgeStreams).length && Object.keys(this.state.remoteBridgeStreams)[0] !== callUUID) {
      console.error("store(setRemoteBridgeStream) call uuid mismatch", { key: Object.keys(this.state.remoteBridgeStreams)[0], id: callUUID });
      return false;
    }
    if (bridgeCallInfo && callUUID in this.state.remoteBridgeStreams) {
      const cleanCallInfo = Object.assign({}, bridgeCallInfo);
      Object.keys(cleanCallInfo).forEach(key => cleanCallInfo[key] === undefined && delete cleanCallInfo[key]);
      const { roomId, roomGuid } = bridgeCallInfo = Object.assign({}, this.state.remoteBridgeStreams[callUUID], cleanCallInfo);
      console.log("store(setRemoteBridgeStream) merged existing remote bridge stream", { callUUID, roomId, roomGuid });
    }
    return (this.state.user['bridgeCallInfo'] = this.state.remoteBridgeStreams[callUUID] = bridgeCallInfo);
  },
  removeCallingUser(uuid, { callUUID }) {
    if (uuid && callUUID) {
      if (this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].calling) {
        let callingList = this.state.remoteBridgeStreams[callUUID].calling
        if(callingList.indexOf(uuid)!=-1){
          callingList.splice(callingList.indexOf(uuid), 1);
          this.state.remoteBridgeStreams[callUUID]['calling'] = callingList;
        }
      }
    }
  },
  addBridgeCallingUser(uuid, callUUID){
    if(callUUID){
      if (this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].calling) {
        let callingList = this.state.remoteBridgeStreams[callUUID].calling
        if(callingList.indexOf(uuid)==-1){
          callingList.push(uuid);
          this.state.remoteBridgeStreams[callUUID]['calling'] = callingList;
        }
      }
    }
  },
  removeRemoteBridgeStreams(callUUID) {
    if (callUUID) {
      delete this.state.remoteBridgeStreams[callUUID];
      this.removeInBridgeCall();
      setTimeout(() => {
        // https://gitlab.ra-micro.de/devcups/voffice/-/issues/349 (Sometimes a call doesn't end)
        syncedUserState(() => {
          const unlikelyCallUUID = callUUID === (this.state.user.inBridgeCall || this.state.user.inBridgeCallListener);
          const remoteBridgeStreamRemoved = !(callUUID in this.state.remoteBridgeStreams);
          if (unlikelyCallUUID && remoteBridgeStreamRemoved) this.removeInBridgeCall();
        });
      }, 300);
    }
  },
  addRemoteFirstStream(uuid, remoteStream) {
    if (!this.state.remoteStreams[uuid]) {
      // this.state.remoteStreams[uuid] = {};
      this.state.remoteStreams[uuid] = { first: undefined, second: undefined };
    }
    this.state.remoteStreams[uuid]['first'] = remoteStream;
  },
  addRemoteSecondStream(uuid, remoteStream) {
    if (!this.state.remoteStreams[uuid]) {
      // this.state.remoteStreams[uuid] = {};
      this.state.remoteStreams[uuid] = { first: undefined, second: undefined };
    }
    this.state.remoteStreams[uuid]['second'] = remoteStream;
  },
  removeRemoteStreams(uuid) {
    delete this.state.remoteStreams[uuid];
  },
  mergeUserStateFromSignalling(sigUserState, deleteMissingKeys) {
    const deletedKeys = deleteMissingKeys &&
      hasOwn.call(this.state.group, this.state.ownUUID) && this.state.lastUserStateSent &&
        Object.keys(this.state.user).filter(key => this.state.user[key] !== undefined && !hasOwn.call(sigUserState, key));
    // console.debug('mergeUserStateFromSignalling', JSON.stringify(sigUserState), deletedKeys);
    // console.debug('mergeUserStateFromSignalling this.state.user', JSON.stringify(this.state.user));
    const newState = mergeState(this.state.user, sigUserState, ['userSettings']);
    if (this.state.user.bridgeCallInfo && Object.keys(this.state.remoteBridgeStreams).length) {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      if (callUUID) {
        const sameReference = this.state.user.bridgeCallInfo === this.state.remoteBridgeStreams[callUUID];
        // Force reference preservation and ignore remote outdated updates of bridge call info.
        if (sameReference) {
          if (this.state.user.inBridgeCall) newState.inBridgeCall = this.state.user.inBridgeCall;
          if (this.state.user.inBridgeCallListener) newState.inBridgeCallListener = this.state.user.inBridgeCallListener;
          newState.bridgeCallInfo = this.state.remoteBridgeStreams[callUUID];
        }
      }
    }
    if (deletedKeys && deletedKeys.length) deletedKeys.forEach(key => delete newState[key]);
    this.state['user'] = newState;
    if (this.state.userOnModal && (this.state.ownModalUser || this.state.userOnModal.uuid === this.state.ownUUID)) {
      if (this.state.user.avatar) this.state.userOnModal['avatar'] = this.state.user.avatar;
      if (this.state.user.email) this.state.userOnModal['email'] = this.state.user.email;
      if (this.state.user.name) this.state.userOnModal['name'] = this.state.user.name;
    }
  },
  mergeUserStateFromSignallingLow(sigUserState) {
    // console.debug('mergeUserStateFromSignallingLow', sigUserState)
    this.state['user'] = { ...sigUserState, ...this.state.user };
  },
  printGroup() {
    // Object.entries(this.state.group).forEach(([uuid, person], index) => console.log(index, uuid, person.connected, (person.user || {}).name, person.permissions));
  },
  getNumberOfCalls() {
    return Object.keys(this.state.remoteStreams).length;
  },
  isAtCallLimit() {
    if (this.state.dev) return false;
    return this.getNumberOfCalls() >= 4;
  },
  setCallFull(value) {
    this.state['isCallFull'] = value;
  },
  isUserManagerByUuid(uuid) {
    const list = this.getProcessedUserList();
    if (list[uuid] && (list[uuid].supervisorOfSections || list[uuid].supervisorOfDepartments || list[uuid].supervisorOfTeams)) {
      return list;
    }
  },
  getProcessedUserList() {
    const users = {};
    // const relations = {};
    const p = this.state.namespaceSettings.processedOrganisation;
    if (!p.teams) p.teams = {};
    if (!p.sections) p.sections = {};
    if (!p.departments) p.departments = {};

    function addLinkedThings(uuid, teamName) {
      if (!users[uuid].teamDepartments) users[uuid].teamDepartments = [];
      if (!users[uuid].teamSections) users[uuid].teamSections = [];

      const departmentName = p.teams[teamName].department;
      const sectionName = departmentName ? p.departments[departmentName].section : undefined;

      if (departmentName && users[uuid].teamDepartments.indexOf(departmentName) === -1) users[uuid].teamDepartments.push(departmentName);
      if (sectionName && users[uuid].teamSections.indexOf(sectionName) === -1) users[uuid].teamSections.push(sectionName);
    }

    const sections = Object.keys(p.sections);
    for (const sectionName of sections) {
      const section = p.sections[sectionName];
      for (const supervisor of section.supervisors) {
        const uuid = supervisor.uuid;
        if (!users[uuid]) users[uuid] = {};
        if (!users[uuid].supervisorOfSections) users[uuid].supervisorOfSections = [];
        if (users[uuid].supervisorOfSections.indexOf(sectionName) === -1) users[uuid].supervisorOfSections.push(sectionName);
      }
    }

    const departments = Object.keys(p.departments);
    for (const departmentName of departments) {
      const department = p.departments[departmentName];
      for (const supervisor of department.supervisors) {
        const uuid = supervisor.uuid;
        if (!users[uuid]) users[uuid] = {};
        if (!users[uuid].supervisorOfDepartments) users[uuid].supervisorOfDepartments = [];
        if (users[uuid].supervisorOfDepartments.indexOf(departmentName) === -1) users[uuid].supervisorOfDepartments.push(departmentName);
      }
    }

    const teams = Object.keys(p.teams);
    for (const teamName of teams) {
      const team = p.teams[teamName];
      for (const supervisor of team.supervisors) {
        const uuid = supervisor.uuid;
        if (!users[uuid]) users[uuid] = {};
        if (!users[uuid].supervisorOfTeams) users[uuid].supervisorOfTeams = [];
        if (users[uuid].supervisorOfTeams.indexOf(teamName) === -1) users[uuid].supervisorOfTeams.push(teamName);
        addLinkedThings(uuid, teamName);
      }

      for (const uuid of team.members) {
        if (!users[uuid]) users[uuid] = {};
        if (!users[uuid].memberOfTeams) users[uuid].memberOfTeams = [];
        if (users[uuid].memberOfTeams.indexOf(teamName) === -1) users[uuid].memberOfTeams.push(teamName);
        addLinkedThings(uuid, teamName);
      }
    }

    return users;
  }
};

export default store;
export { EventBus };

setInterval(store.updateTS.bind(store), 5000);

export function isIframe() {
  let isIframe;
  try {
    isIframe = window !== window.parent && !window.opener && window.self !== window.top;
  } catch (e) {
    isIframe = true;
  }
  return isIframe;
}

export function removeA(arr) {
  var what; var a = arguments; var L = a.length; var ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

/**
 * @param {object} first The first source object from which to copy properties.
 * @param {object} second The second source object from which to copy properties.
 * @param {string[]} props Properties containing objects to merge below root.
 */
export function mergeState(first, second, props = []) {
  if (second === null || typeof second !== 'object' || second === first) return first;
  const result = {};
  const merged = {};
  for (const prop of props) {
    if (
      hasOwn.call(first, prop) && first[prop] && typeof first[prop] === 'object' &&
      hasOwn.call(second, prop) && second[prop] && typeof second[prop] === 'object'
    ) {
      merged[prop] = Object.assign({}, first[prop], second[prop]);
    }
  }
  // The second parameter is the new state and we must respect and preserve key ordering
  for (const key in second) {
    if (hasOwn.call(second, key)) {
      result[key] = merged[key] || second[key];
    }
  }
  return Object.assign(result, first, second, merged);
}

let inited = false;
export function loadUserState() {
  try {
    const storedState = (isIframe() ? sessionStorage : localStorage).getItem('userState_' + store.state.ownUUID);
    const userState = JSON.parse(storedState);
    const newState = mergeState(store.state.user, userState, ['userSettings']);
    // console.log('loadUserState newState:', JSON.stringify(newState));
    // if (newState.name === 'Someone' || newState.name === '') {
    //   askForName();
    //   // newState.name = store.state.user.name;
    // }
    if (!inited || JSON.stringify(store.state.user) !== JSON.stringify(newState)) {
      inited = true;
      // console.log('Applying loaded state', JSON.stringify(store.state.user), '==>', JSON.stringify(newState));
      store.state['user'] = newState;
    } else {
      // console.log('No need to apply loaded state:', JSON.stringify(store.state.user), '==>', JSON.stringify(newState));
    }
  } catch (err) {
    console.warn('Failed to load user state:', err);
  }
}

export function loadPersistedState() {
  try {
    const storedState = (isIframe() ? sessionStorage : localStorage).getItem('persistedState_' + store.state.ownUUID);
    const persistedState = JSON.parse(storedState);
    const newState = mergeState(store.state.persisted, persistedState, ['mediaDeviceSetup']);
    store.state['persisted'] = newState;
  } catch (err) {
    console.warn('Failed to load persisted state:', err);
  }
}

export function askForName() {
  const defaultName = 'Lazy Dude #' + Math.floor(Math.random() * 10000);
  if (hashValue) {
    // If hashValue, take default and wait for sync from signalling
    return store.state.user['name'] = defaultName;
  }
  // const name = window.prompt('Identify Yourself', ''); // defaultName);
  // store.state.user['name'] = name || defaultName;
}

export function saveUserState() {
  try {
    // console.log('saveUserState', store.state.user);
    (isIframe() ? sessionStorage : localStorage).setItem('userState_' + store.state.ownUUID, JSON.stringify(store.state.user));
  } catch (err) {
    console.warn('Failed to save user state:', err);
  }
}

let pokeSendPersistedStateTimer;
function pokeSendPersistedState() {
  if (pokeSendPersistedStateTimer) {
    clearTimeout(pokeSendPersistedStateTimer);
  }
  // console.log('pokeSendPersistedState Timer set');
  const localTimer = pokeSendPersistedStateTimer = setTimeout(async () => {
    await removedSplashPromise; // Ensure frontend is loaded and connected.
    if (localTimer !== pokeSendPersistedStateTimer) return;
    // console.log('pokeSendPersistedState Calling ws');
    wsCall('setUserPersist', store.state.persisted);
    pokeSendPersistedStateTimer = undefined;
  }, 3000);
}

export function savePersistedState() {
  try {
    // console.log('savePersistedState', store.state.persisted);
    (isIframe() ? sessionStorage : localStorage).setItem('persistedState_' + store.state.ownUUID, JSON.stringify(store.state.persisted));
    // console.log('savePersistedState - savedPersistedStateEvent:', savedPersistedStateEvent);
    savedPersistedStateEvent({ ...store.state.persisted });
    // wsCall('setUserPersist', store.state.persisted);
    pokeSendPersistedState();
  } catch (err) {
    console.warn('Failed to save persisted state:', err);
  }
}

let receivedAllUserStatePromise = new Promise((resolve) => {
  const unwatch = receivedAllUserStateEvent.watch(() => {
    unwatch();
    return resolve();
  });
});

export async function syncedGroupState(startup = false) {
  if (!startup) {
    await syncedUserState().catch((err) => {
      console.warn('syncedGroupState: syncedUserState rejection', err);
    });
  }
  await receivedAllUserStatePromise;
  const len = Object.keys(store.state.group).length;
  console.assert(len && store.state.group === allUsersState.getState(), 'Inconsistent group state.');
}

export async function syncedUserState(func, unsafe = false) {
  const userTS = (store.state.group[store.state.ownUUID] || {}).userTS;
  if (!userTS && !unsafe) throw new Error('Invalid user state. No timestamp found in group entry.');
  const timeout = setUserStateTimeout ? 1200 : 900;
  const step = 300;
  let ticks = timeout / step;
  let retVal;
  if (func) {
    // Wait for user locked and update
    if (
      (setUserStateTimeout) || // An update is queued
      (JSON.stringify(store.state.user) !== JSON.stringify((store.state.group[store.state.ownUUID] || {}).user || {})) // There are more updates to commit
    ) await syncedUserState(undefined, unsafe);
    // Execute callback
    const oldVal = JSON.stringify(store.state.user);
    retVal = await func();
    if (oldVal === JSON.stringify(store.state.user)) return retVal; // No changes
  }
  do {
    await aDelay(step);
    const newUserTS = (store.state.group[store.state.ownUUID] || {}).userTS;
    if (
      (!setUserStateTimeout) && // An update is not queued
      (userTS !== newUserTS) && // Server processed the update
      (JSON.stringify(store.state.user) === JSON.stringify((store.state.group[store.state.ownUUID] || {}).user || {})) // There are no more updates to commit
    ) return retVal;
  } while (--ticks > 0);
  if (func) console.warn('syncedUserState timeout');
  return retVal;
}

let setUserStateTimeout;
let lastSendUserState = Date.now();

export function actuallySendUserState(startup = false) {
  if (setUserStateTimeout) clearTimeout(setUserStateTimeout);
  setUserStateTimeout = undefined;
  lastSendUserState = Date.now();
  if (Vue.prototype.$locale && store.state.user.language !== Vue.prototype.$locale.current()) store.state.user['language'] = Vue.prototype.$locale.current(); // Set user language
  if (store.state.user.isMobile !== isMobile()) store.state.user['isMobile'] = isMobile(); // Make sure isMobile has the correct value
  if (store.state.user.uuid !== store.state.ownUUID) store.state.user['uuid'] = store.state.ownUUID; // Workaround for uuid inconsistency
  if (store.state.user.connected !== undefined) delete store.state.user['connected']; // Delete connected property from state.user
  const j = JSON.stringify(store.state.user);
  if (store.state.lastUserStateSent !== j) {
    // console.debug('Sending update state, last', store.state.lastUserStateSent, '==>', j);
    store.state['lastUserStateSent'] = j;
    wsCall('setUserState', store.state.user);
    return syncedUserState(undefined, startup).catch((err) => {
      if (!startup) console.warn('actuallySendUserState: syncedUserState rejection', err);
    });
  }
  const curr = JSON.stringify((store.state.group[store.state.ownUUID] || {}).user || {});
  if (j !== curr) {
    // console.debug('Sending update state, group', curr, '==>', j);
    store.state['lastUserStateSent'] = j;
    wsCall('setUserState', store.state.user);
    return syncedUserState(undefined, startup).catch((err) => {
      if (!startup) console.warn('actuallySendUserState: syncedUserState rejection', err);
    });
  }
  return Promise.resolve();
}

export function sendUserState() {
  if (setUserStateTimeout) clearTimeout(setUserStateTimeout);
  if (Date.now() - lastSendUserState > 2000) {
    setUserStateTimeout = setTimeout(actuallySendUserState, setUserStateTimeout ? 10 : 0);
  } else {
    setUserStateTimeout = setTimeout(actuallySendUserState, 300);
  }
}

export function storeInit() {
  store.state = reactive(store.state);
  store.state['ownUUID'] = getOwnUUID();
  loadUserState();
  loadPersistedState();
}

try {
  document.store = store;
} catch (err) { }

function pokeMobileTimer() {
  try {
    store.state.user['mobileLastActive'] = Date.now();
  } catch (err) {
    console.warn('pokeMobileTimer Error', err);
  }
}

try {
  if (isMobile()) {
    pokeMobileTimer();
    setInterval(pokeMobileTimer, 5000);
  }
} catch (err) {
  console.warn('Mobile timer setup err', err);
}
