<template>
  <div class="settings-right">
    <v-menu offset-y :model-value="showMenu" @update:model-value="showMenu = $event">
      <template v-slot:activator="{ props }">
        <v-btn icon color="default" v-bind="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-on="props"
                class="text-primary settingsIcon"
                :icon="['fal', 'cog']"
                :style="{ fontSize: '20px' }"
              />
            </template>
            <span>{{ $t("generics.settings") }} </span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-list>
        <!-- <v-list-item-group> -->

          <v-list-item
            v-for="(item, i) in getSortedList"
            :key="i"
            @click="
              item.index === 0
                ? setShowLanguage()
                : item.index === 1
                ? toggleCamMic()
                : null
            "
          >
            <v-icon>
              <font-awesome-icon
                v-if="item.index == 0"
                :icon="['fal', 'language']"
                :style="{ fontSize: '16px' }"
              />
              <font-awesome-icon
                v-if="item.index == 1"
                :icon="['fal', 'video']"
                :style="{ fontSize: '16px' }"
              />
              <!-- <img
                v-if="item.index == 1"
                class="camMicIcon"
                src="img/icons/cam-mic-settings.svg"
              /> -->
            </v-icon>
            <v-list-item-title>
              <span>{{ item.name }}</span>
            </v-list-item-title>
          </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import { setLanguageModalEvent } from "../../effector/modals";
export default {
  data: () => ({
    state: store.state,
    showMenu: false,
  }),
  methods: {
    toggleCamMic() {
      EventBus.$emit("togleMicSettings");
    },
    setShowLanguage() {
      setLanguageModalEvent(true);
    },
  },
  computed: {
    getSortedList() {
      return [
        { index: 0, name: this.$t("generics.language") },
        { index: 1, name: this.$t("components.navbar.camAndMic") },
      ].sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    hasPrivilege() {
      return hasPrivilege(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.camMicIcon {
  width: 20px;
  height: auto;
}
</style>