<template>
<div class="px-0 py-0" style="width:100%;height: calc(100vh - 130px);">
  <v-row class="mx-0">
      <v-card flat :width="'100%'">
        <div>
          <v-row class="mx-0">
            <v-col cols="12" :lg="12" :sm="12" :md="12" :xl="12">
              <v-sheet tile height="54" color="primary-lighten-1" class="d-flex"> <!-- :color="!theme.global.current.dark ? 'grey lighten-3': null" :class="{'calendarDark': theme.global.current.dark}" -->

                <v-col cols="4" class="px-2 py-3">
                  <span class="text-subtitle-1 font-weight-bold py-2">{{ $t('components.calendarContainer.videoCallPlanner') }} {{getUserNameUserCalendar(uuidToRepresentStore || state.ownUUID)}} </span>
                 <v-btn variant="text" class="refreshData"  icon  @click="toggleHome()">
                  <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                </v-btn>
                 <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn  variant="text" v-bind="props" class="closeHome"  icon  @click="fetchBlockedData()">
                        <font-awesome-icon :icon="['far', 'redo']" :style="{ fontSize: '16px' }" />
                      </v-btn>
                    </template>
                      <span>{{ $t('generics.update') }}</span>
                 </v-tooltip>

                </v-col>
                <!-- <v-col cols="4" class="px-2 py-3 text-center">
                  <v-btn icon class="btnCalendar" @click="prevMonth()">
                     <font-awesome-icon :icon="['fal', 'chevron-left']" :style="{ color: 'white', fontSize: '20px' }" />
                  </v-btn>
                  <span class="text-white text-subtitle-1 font-weight-bold">{{calendarMonth}} {{calendarYear}}</span>
                  <v-btn icon class="btnCalendar" @click="nextMonth()">
                     <font-awesome-icon :icon="['fal', 'chevron-right']" :style="{ color: 'white', fontSize: '20px' }" />
                  </v-btn>
                </v-col> -->

                <!-- <v-col cols="4" class="px-0 py-0">
                  <v-select return-object :model-value="type" @update:model-value="type = $event" item-text="name" item-value="value" :items="types" density="compact" outlined hide-details :label="$t('components.calendarContainer.type')" class="ma-2" ></v-select>
                </v-col> -->

                <!-- <v-col cols="4" class="px-0 py-0">
                  <v-select v-if="hasRepresentative && 1 === 2" return-object :model-value="representing" @update:model-value="representing = $event" item-text="name" item-value="value" :items="peopleIRepresent" density="compact" outlined hide-details :label="$t('generics.user')"  class="ma-2"></v-select>
                </v-col> -->
                <!-- <v-select return-object :model-value="mode" @update:model-value="mode = $event" item-text="name" item-value="vale" :items="modes" density="compact" outlined hide-details :label="$t('components.calendarContainer.eventOverlap')"  class="ma-2"></v-select> -->
                <!-- <v-select :model-value="weekday" @update:model-value="weekday = $event" :items="weekdays" density="compact" outlined hide-details :label="$t('components.calendarContainer.weekdays')"  class="ma-2"></v-select> -->

                <!-- <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn> -->
              </v-sheet>
              <v-sheet v-if="calendarApp" class="calendarContainer">
                <!-- <v-calendar
                  class="calendarSize"
                  ref="calendar"
                  @change="calChangeHandler"
                  :model-value="value"
                  @update:model-value="value = $event"
                  :start="startCalendarDay"
                  :end="endCalendarDate"
                  :weekdays="weekday"
                  :type="'month'"
                  :events="events"
                  :event-overlap-mode="mode.value"
                  :event-overlap-threshold="30"
                  :event-color="getEventColor"
                  @click:event="showEvent"
                  @click:day="syncTimeline"
                  @click:date="syncTimeline"
                  :locale="this.$locale.current()">
                </v-calendar> -->
                <v-row>
                  <v-col>
                    <v-btn variant="outlined" class="ml-2 mr-4" :color="isDarkMode ?'white' : 'primary'" @click="setCalendarToday()">
                      {{ $t("components.workingTime.today") }}
                    </v-btn>
                    <v-btn
                      icon
                      class="ml-2 btnCalendar"
                      density="compact"
                      variant="text"
                      :color="isDarkMode ?'white' : 'primary'"
                      @click="setCalendarPreviousPage()"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'chevron-left']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                    <v-btn
                      icon
                      class="btnCalendar"
                      density="compact"
                      variant="text"
                      :color="isDarkMode ?'white' : 'primary'"
                      @click="setCalendarNextPage()"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'chevron-right']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                    <span :class="isDarkMode ?'text-white' : ''" class="text-subtitle-1 font-weight-bold ml-3">
                      {{ calendarMonth }} {{ calendarYear }}
                    </span>
                  </v-col>
                </v-row>

                <!-- Schedule - X -->
                <ScheduleXCalendar
                    class="mt-4 calendarHeight"
                    :calendar-app="calendarApp"
                  >
                   <!-- MonthGrid event slot -->
                   <template #monthGridEvent="{ calendarEvent }">
                    <v-menu :id="calendarEvent.id" location="end" min-width="150px">
                      <template  v-slot:activator="{ props }">
                        <div
                          v-bind="props"
                          class="eventTemplate text-white px-2"
                          :style="{ backgroundColor: getEventColor(calendarEvent) }"
                          v-tooltip.top="`${ calendarEvent.title }, ${getFormatedTime(calendarEvent.start)} - ${getFormatedTime(calendarEvent.end)}`"
                        >
                          {{ calendarEvent.title }}, {{ getFormatedTime(calendarEvent.start) }} - {{ getFormatedTime(calendarEvent.end) }}
                        </div>
                      </template>
                      <v-card v-if="(calendarEvent.type &&  calendarEvent.type === 'custom' &&
                                      (selectedUser === state.ownUUID || amIAdmin)) !== undefined"
                                      color="grey-lighten-4" flat @click.stop>
                        <v-toolbar density="compact">
                          <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                          <v-toolbar-title
                            :style="{ fontSize: '15px!important' }"
                            v-html="$sanitize(calendarEvent.title)"
                          ></v-toolbar-title>
                          <!-- <v-spacer></v-spacer> -->
                          <DeleteBasket
                            :indexData="calendarEvent"
                            :delFunction="removeEvent"
                            extraClasses="cursorPointer mx-3"
                            fontSize="18"
                          />
                        </v-toolbar>
                      </v-card>
                    </v-menu>
                  </template>
                </ScheduleXCalendar>

                <!-- show events -->
                <v-menu :model-value="selectedOpen" @update:model-value="selectedOpen = $event" :close-on-content-click="false" :activator="selectedElement" offset-x>
                  <v-card v-if="!wantDelete" color="grey-lighten-4" max-width="350px" width="350px" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <!-- <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>-->
                      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                      <v-toolbar-title class="text-truncate" v-html="sanitizeSelectedEventName"></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn v-if="checkImSupervisor" icon >
                        <DeleteBasket :indexData="selectedEvent.uuid" :delFunction="deleteEvent" extraClasses="" fontSize="20" prevent= false />
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span class="text-truncate" v-html="sanitizeSelectedEventDetails"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="secondary" @click="selectedOpen = false">{{ $t('generics.cancel') }}</v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- REPLACED WITH DELETE BASKET  -->
                  <!-- add card for aceept delete -->
                  <!-- <v-card v-if="wantDelete" color="grey lighten-4" max-width="350px" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title class="text-truncate" v-html="'delete event ' + sanitizeSelectedEventName"></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <span class="text-truncate" v-html="sanitizeSelectedEventDetails"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="secondary" @click="wantDelete = false">{{ $t('generics.cancel') }}</v-btn>
                      <v-btn text color="secondary" @click="deleteEvent(selectedEvent.uuid)">accept</v-btn>
                    </v-card-actions>
                  </v-card> -->
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-card>
  </v-row>
</div>
</template>

<script>
import { shallowRef } from "vue";
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store, { syncedGroupState, syncedUserState } from "../../store";
import NewEventForm from '../timeline/newEventForm.vue'
import { setEventStateModalEvent } from '../../effector/modals';
import { removedSplashPromise } from "../../lib/splashAndTheme";
import { getBlockedTimes } from '../../lib/wsMsg';
import { timelineEvents, dispatchFetchMyItemsForDateEvent, newTimelineItemEvent, deleteTimelineItemEvent, pleaseShowDateInTimelineEvent } from '../../effector/timeline';
import { uuidToRepresentStore } from '../../effector/representative';
import { dispatchErrorAlert } from "../../effector/alerts";
import DeleteBasket from "../ui/deleteBasket.vue";

import { ScheduleXCalendar } from '@schedule-x/vue';
import { createCalendar, viewMonthGrid } from '@schedule-x/calendar';
// import { createEventsServicePlugin } from '@schedule-x/events-service';
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls';
import { createEventRecurrencePlugin, createEventsServicePlugin  } from "@schedule-x/event-recurrence";
import '@schedule-x/theme-default/dist/index.css';
import { getTimezone } from "../../utils/basicFunctions";
// import { hasRepresentative } from "../../utils/representatives";

// import {
//   getSectionCalendar,
//   setSectionCalendar,
//   deleteSectionCalendarEvent
// } from "../../lib/wsMsg";
// import {
//   v4 as uuidv4
// } from "uuid";

export default {
  components: { NewEventForm, DeleteBasket, ScheduleXCalendar },
  data() {
    const effector = {
      rawEvents: timelineEvents,
      uuidToRepresentStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      calendarApp: shallowRef(null),
      eventsServicePlugin: null,
      createEventRecurrencePlugin: null,
      calendarControls: null,
      calendarSelectedValue: [new Date()],
      typeView: "month",
      calendarMonth: '',
      calendarYear: '',
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      representing: {
        name: store.state.user.name,
        value: store.state.ownUUID
      },
      type: {
        name: this.$t("components.calendarContainer.monthly"),
        value: "month"
      },
      types: [
        { name: this.$t("components.calendarContainer.monthly"), value: "month" },
        { name: this.$t("components.calendarContainer.week"), value: "week" },
        { name: this.$t("components.calendarContainer.day"), value: "day" }
      ],
      mode: {
        name: this.$t("components.calendarContainer.column"),
        value: "column"
      },
      modes: [
        { name: this.$t("components.calendarContainer.stack"), value: "stack" },
        {
          name: this.$t("components.calendarContainer.column"),
          value: "column"
        }
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {
          text: this.$t("components.calendarContainer.rageDays.SunSat"),
          value: [0, 1, 2, 3, 4, 5, 6]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonSun"),
          value: [1, 2, 3, 4, 5, 6, 0]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonFri"),
          value: [1, 2, 3, 4, 5]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonWedFri"),
          value: [1, 3, 5]
        }
      ],
      value: [new Date()],
      // events: [],
      colors: [
        {
          name: this.$t("components.calendarContainer.colors.blue"),
          value: "blue"
        },
        {
          name: this.$t("components.calendarContainer.colors.indigo"),
          value: "indigo"
        },
        {
          name: this.$t("components.calendarContainer.colors.deepPurple"),
          value: "deep-purple"
        },
        {
          name: this.$t("components.calendarContainer.colors.cyan"),
          value: "cyan"
        },
        {
          name: this.$t("components.calendarContainer.colors.green"),
          value: "green"
        },
        {
          name: this.$t("components.calendarContainer.colors.orange"),
          value: "orange"
        },
        {
          name: this.$t("components.calendarContainer.colors.greyDarken"),
          value: "grey darken-1"
        }
      ],
      colorSelected: {
        name: this.$t("components.calendarContainer.colors.blue"),
        value: "blue"
      },
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party"
      ],
      startEvent: "",
      startMenu: false,
      startTimeMenu: false,
      timeStart: null,
      endEvent: "",
      endMenu: false,
      endTimeMenu: false,
      timeEnd: null,
      eventName: "",
      eventDetail: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      wantDelete: false,
      myReservedEvents: [],
      // Effector
      ...effector,
    };
  },
  methods: {
    setCalendarApp() {
      const localeLang = this.$locale.current();
      const todayDate = new Date();
      this.calendarSelectedValue = [todayDate];
      const calendarEvents = this.getFormattedEvents();
      this.calendarApp = createCalendar({
        // ... views, events and other options
        views: [viewMonthGrid],
        events: calendarEvents,
        plugins: [this.eventsServicePlugin, this.calendarControls, this.createEventRecurrencePlugin],
        // this.createEventRecurrencePlugin
        /**
         * Set the language. List of supported languages: https://schedule-x.dev/docs/calendar/supported-languages
         * For support of further languages, please open a PR, adding your translations under the folder:
         * packages/translations/src/locales/xx-XX
         *
         * Defaults to 'en-US'
         * Adjusted to use ONLY 'es-ES', 'de-DE' or 'en-US' as fallback for any other thing
         *
         */
        locale: localeLang == 'de' || localeLang == 'es' ? `${localeLang}-${localeLang.toUpperCase()}` : 'en-US',

        /**
         * Set which day is to be considered the starting day of the week. 0 = Sunday, 1 = Monday, (...other days) 6 = Saturday
         * Defaults to 1 (Monday)
         */
        firstDayOfWeek: 1,

        /**
         * The preferred view to display when the calendar is first rendered.
         * all views that you import have a "name" property, which helps you identify them.
         * Defaults to the first view in the "views" array
         */
        defaultView: viewMonthGrid.name,

        /**
         * The default date to display when the calendar is first rendered. Only accepts YYYY-MM-DD format.
         * Defaults to today's date
         */
        selectedDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),

        /**
         * Render the calendar in dark mode.
         * Defaults to false
         */
        isDark: this.isDarkMode,

        /**
         * Decides which hours should be displayed in the week and day grids. Defaults to midnight - midnight (a full day)
         * Can also be set to a "hybrid" day, such as { start: '06:00', end: '03:00' }, meaning each day starts at 6am but
         * extends into the next day until 3am.
         */
        // dayBoundaries: {
        //   start: '05:00',
        //   end: '20:00',
        // },

        /**
         * The minimum and maximum date that can be displayed
         * Defaults to undefined, meaning no minimum or maximum date
         */
        // minDate: '2024-01-01',
        // maxDate: '2024-12-31',

        weekOptions: {
          /**
           * The total height in px of the week grid (week- and day views)
           */
          gridHeight: 1000,
        },

        monthGridOptions: {
          /**
           * Number of events to display in a day cell before the "+ N events" button is shown
           */
          nEventsPerDay: 3
        },

        /**
         * Callbacks for events that occur in the calendar
         */
        callbacks: {
          /**
           * Is called when:
           * 1. Selecting a date in the date picker
           * 2. Selecting a new view
           */
          onRangeUpdate: (range) =>{
            // console.log('new calendar range start date', range.start)
            // console.log('new calendar range end date', range.end)
            // this.checkCalendarBoundaries();
          },

          /**
           * Is called when an event is updated through drag and drop
           */
          onEventUpdate(updatedEvent) {
            // console.log('onEventUpdate', updatedEvent)
          },

          /**
           * Is called when an event is clicked.
           * Using arrow function to have access to 'this' from the Vue instance.
           */
          onEventClick: (calendarEvent) => {
            console.log('onEventClick', calendarEvent);
            // console.log('onEventClick nativeEvent', this);
            // #463 - We just want to open the tooltip with deleteBasket
            // this.openEvent(this, calendarEvent);
          },

          /**
           * Is called when clicking a date in the month grid
           */
          onClickDate: (date) => {
            console.log('onClickDate', date) // e.g. 2024-01-01
            const dateToSet = moment(new Date(date)).format('YYYY-MM-DD');
            // this.calendarControls.setDate(dateToSet);
            this.setDateinCaledar(dateToSet);
            // this.changeCalendarTypeView('week');
          },

          /**
           * Is called when clicking somewhere in the time grid of a week or day view
           */
          onClickDateTime: (dateTime) => {
            // console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
            const dateToSet = moment(new Date(dateTime)).format('YYYY-MM-DD');
            // this.calendarControls.setDate(dateToSet);
            this.setDateinCaledar(dateToSet);
          },

          /**
           * Is called when selecting a day in the month agenda
           */
          onClickAgendaDate(date) {
            // console.log('onClickAgendaDate', date) // e.g. 2024-01-01
          },

          /**
           * Is called when double clicking a date in the month grid
           */
          onDoubleClickDate(date) {
            // console.log('onClickDate', date) // e.g. 2024-01-01
          },

          /**
           * Is called when double clicking somewhere in the time grid of a week or day view
           */
          onDoubleClickDateTime(dateTime) {
            // console.log('onDoubleClickDateTime', dateTime) // e.g. 2024-01-01 12:37
          },

          /**
           * Is called when clicking the "+ N events" button of a month grid-day
           */
          onClickPlusEvents(date) {
            console.log('onClickPlusEvents', date) // e.g. 2024-01-01
          },

          /**
           * Is called when the selected date is updated
           */
          onSelectedDateUpdate: (date) => {
            // console.log('onSelectedDateUpdate', date);
          },
        },
      })
    },
    getFormattedEvents() {
      let events = [];
      if (!this.rawEvents.length) return events;
      this.rawEvents.forEach( entry => { // We don't skip old events as they can be recurring
        let myOwnEvent = {
          // ...entry,
          id: entry.confId,
          title: entry.name || entry.title,
          start: this.formatDateString(entry.start),
          end: this.formatDateString(entry.end),
          color: entry.color,
        }
        if (entry.isReOcurring) {
          if (entry.confIsReoInterval !== 'm'){
            myOwnEvent = this.addReoCurringData(myOwnEvent, entry);
            events.push(myOwnEvent);
          } else if (entry.confIsReoInterval === 'm'){
            events.push(myOwnEvent);
            const populatedMonthlyEvents = this.createNextMonthlyEvents(myOwnEvent, entry);
            events = [...populatedMonthlyEvents, ...events];
          }
        }
      });
      if (this.myReservedEvents && this.myReservedEvents.length){
        this.myReservedEvents.forEach(block => {
          events.push(block);
        });
      }
      return events;
    },
    addReoCurringData(calObj, event) {
      /*
        Reference for rrule building at:
          https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.10
          https://schedule-x.dev/docs/calendar/plugins/recurrence#usage
      */
      if (event.confIsReoNumberOfRepetitions === -1) return calObj; // This means it is the last repetition of this event so we don't need to populate it
      let rrule;
      switch (event.confIsReoInterval) {
        case 'd':
          rrule = `FREQ=DAILY;INTERVAL=${event.confIsReoRepeatEvery || 1};`;
        break;
        case 'w':
          rrule = `FREQ=WEEKLY;INTERVAL=${event.confIsReoRepeatEvery || 1};`;
          // eslint-disable-next-line no-case-declarations
          const byDay = this.convertWeekDaysNumberToText( event.confIsReoRecurringDays );
          if (byDay) rrule = `${rrule}BYDAY=${byDay};`;
        break;
        case 'm':
          /* Monthly events are broken so we create each event manually without repetition or rrule param */
          // isMonthlyEvent = true;
          // rrule = `FREQ=MONTHLY;INTERVAL=${event.confIsReoRepeatEvery || 1};`;
          // if (event.confIsReoMonthByDay == "dayNum") {
          //   if (event.confIsReoMonthByOriginalDay) rrule = `${rrule}BYMONTHDAY=${event.confIsReoMonthByOriginalDay};`;
          // } else if (event.confIsReoMonthByDay == "weekPos") {
          //   /* ToDo: Keep fighting here until we get it */
          //   let weekNumber;
          //   if (event.confIsReoMonthByOriginalDay){
          //     weekNumber = Math.ceil(event.confIsReoMonthByOriginalDay / 7);
          //     if (weekNumber >= 4) weekNumber = -1; // This indicates last week of the month
          //   }
          //   rrule = `FREQ=MONTHLY;`; //BYWEEKNO=${weekNumber}
          //   const byDay = this.convertWeekDaysNumberToText( event.confIsReoRecurringDays );
          //   if (byDay) rrule = `${rrule}BYDAY=${weekNumber}${byDay};`;
          // }
        break;
      }
      // Common params at every Interval
      if (event.confIsReoNumberOfRepetitions) { rrule = `${rrule}COUNT=${event.confIsReoNumberOfRepetitions};`;} else { rrule = `${rrule}COUNT=999;`} // Monthly Frequency seems not to work without COUNT
      if (event.confIsReoEndDate) rrule = `${rrule}UNTIL=${event.confIsReoEndDate};`;

      const reoEvent = {
        ...calObj,
        rrule
      }
      return reoEvent;
    },
    /**
     * Populates monthly event creating individual events (without reocurring or rrule)
     * for each ocurrence, maximum 99 ocurrences unless user specifies more
     *
     */
    createNextMonthlyEvents(calObj, event) {
      const { ianaTimezone = 'Europe/Berlin', start, confIsReoMonthByDay, confIsReoEndDate, confIsReoNumberOfRepetitions, confIsReoMonthByOriginalDay, confIsReoRepeatEvery } = event;
      let eventStartMoment = moment(start).tz(ianaTimezone);
      const unit = 'month';
      const amount = parseInt(confIsReoRepeatEvery, 10) || 1;
      const populatedEvents = [];
      // populatedEvents.push(event);
      const calendarNumberOfRepetitions = confIsReoNumberOfRepetitions > 0 ? confIsReoNumberOfRepetitions : 20; // 0 means no limit so we adjust it to 20
      for (let rep = 0; rep < calendarNumberOfRepetitions; rep++) {
        if (event.confIsReoMonthByDay === "dayNum") { // Ex: Every day 15 each month
          if (confIsReoMonthByOriginalDay < 28){
            eventStartMoment.add(amount, unit);
            eventStartMoment.date(confIsReoMonthByOriginalDay); // Fix bug when adding more than one month
          } else {
            // After the 28th, consider it can be the last day of the month (not on a weekend)
            eventStartMoment.add(amount, unit); // Add months
            // we take the originalDay from confIsReoMonthByOriginalDay to avoid date issues
            if (typeof confIsReoMonthByOriginalDay === 'number') {
              const targetDate = eventStartMoment.clone().date(confIsReoMonthByOriginalDay);

              // Adjust the date if it falls within a valid range for the new month
              if (targetDate.isValid() && targetDate.month() === eventStartMoment.month()) {
                eventStartMoment.date(confIsReoMonthByOriginalDay);
              } else {
                // Handle the case where the day is invalid for the new month
                eventStartMoment.endOf('month');
              }
            }
            // If the last day is a weekend (Saturday or Sunday), adjust to the previous Friday
            if (eventStartMoment.isoWeekday() === 6 || eventStartMoment.isoWeekday() === 7) {
              eventStartMoment = eventStartMoment.subtract(eventStartMoment.isoWeekday() - 5, 'days');
            }
            // checkIfWeekend = false; // Should not do anything, just skip functionality by performance reasons
          }
        } else if (confIsReoMonthByDay === "weekPos") { // Ex: First||Last monday of the month
          const confStartTime = start.split('T')[1];
          const confTimezone = eventStartMoment.format('Z');
          const eventNextStartMoment = this.getNextMonthlyConferenceStart(eventStartMoment, confStartTime, confTimezone, amount);
          if (eventNextStartMoment === eventStartMoment) continue; // Avoid adding duplicates
          eventStartMoment = eventNextStartMoment; // Replace original moment
        }
        if (confIsReoMonthByDay !== "weekPos") {
          let eventStartWeekDay = eventStartMoment.isoWeekday();
          if ([6 /* Saturday */, /* Sunday */ 7].includes(eventStartWeekDay)) {
            eventStartMoment = moment(eventStartMoment).add(8 - eventStartWeekDay, 'day');
            eventStartWeekDay = eventStartMoment.isoWeekday(); // Moved to Monday
          }
        }
        // Check if confIsReoEndDate is defined and compare dates correctly
        if (confIsReoEndDate && eventStartMoment.isAfter(moment(confIsReoEndDate).add(1, 'day'))) {
          // Last day included in this way
          return; // This event is not re-occurring anymore
        }
        const duration = event.isUnlimited ? 0 : event.confDuration;
        const eventEndMoment = moment(eventStartMoment).add(duration, 'minutes');
        // const eventFrequency = event.confIsReoFrequency > 0 ? (event.confIsReoFrequency - 1) : event.confIsReoFrequency;
        const eventStartISO = eventStartMoment.toISOString();
        const eventEndISO = eventEndMoment.toISOString();
        const updatedEventData = {
          // color: 'orange',
          start: eventStartISO,
          end: eventEndISO,
          // confIsReoFrequency: +eventFrequency || 0,
          // updatedServerTs: Date.now(),
        };
        const newEvent = Object.assign({}, calObj, updatedEventData);
        populatedEvents.push(newEvent);
      }
      return populatedEvents;
    },
    /**
     * Converts an array of numerical weekday representations
     * into their corresponding abbreviated text form, separated by commas.
     *
     * @param {number[]} weekDays - An array of numbers where each number represents
     * a day of the week, starting from 0 (Monday) to 6 (Sunday).
     * @returns {string} - A string representing the abbreviated names
     * of the days in the week based on the input indexes, separated by commas.
     */
    convertWeekDaysNumberToText(weekDays) {
      if (!weekDays || !weekDays.length || typeof weekDays[0] !== 'number') return '';
      const weekDaysTexts = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
      return weekDays.map(day => weekDaysTexts[day]).join(',');
    },
    /**
     * Calculates the start date and time for the next monthly event.
     * This function goes to the next month
     *
     * @param {moment} conferenceStartMoment The initial event start moment object.
     * @param {string} confStartTime The start time for the event in HH:mm format.
     * @param {string} confTimezone The timezone in which the event start time should be calculated.
     * @param {number} amount The amount of months to skip.
     * @returns {moment} A moment object representing the start date and time of the next monthly event.
     */
    getNextMonthlyConferenceStart(conferenceStartMoment, confStartTime = '', confTimezone = 'Z', amount = 1) {
      if (typeof confStartTime !== 'string' || isNaN(amount)) {
        console.error('getNextMonthlyConferenceStart: confStartTime is not a string or amount is not a number');
        return conferenceStartMoment;
      }
      const timeZone = moment(conferenceStartMoment).tz();
      const conferenceStartDate = moment(conferenceStartMoment).toDate();
      if (isNaN(conferenceStartDate)) {
        console.error('getNextMonthlyConferenceStart: conferenceStartMoment is not valid');
        return conferenceStartMoment;
      }
      const day = conferenceStartDate.getDate(); // getDate() gets real day number (1-31)
      const weekNumber = Math.ceil(day / 7);
      const dayOfWeek = conferenceStartDate.getDay(); // getDay() gets day of the week in number format (1-7)
      let nextMonth = conferenceStartDate.getMonth() + amount; // We add amount to the current month but...
      let year = conferenceStartDate.getFullYear();
      if (nextMonth > 11) { // ...check if it's next year: `nextMonth` exceeds 11 instead of 12, due to zero-indexed month values in JavaScript Dates
        nextMonth = 0;
        year += 1;
      }
      // Start by day 1 of next month
      let date = new Date(year, nextMonth, 1, 1, 1, 1);
      // Now find the correct week number
      if(weekNumber <= 3) {
        // Find the desired day of the week and then weekNumber
        while (date.getDay() !== dayOfWeek) {
          date.setDate(date.getDate() + 1);
        }
        date.setDate(date.getDate() + (weekNumber - 1) * 7);
      } else { // Finding the desired day 'dayOfWeek' at the last week of next month
        // We need to start at the end and move backwards until we find the desired day
        date = new Date(year, nextMonth + 1, 0); // trick! 0th day of next - next month is the last day of current next month
        while (date.getDay() !== dayOfWeek) {
          date.setDate(date.getDate() - 1);
        }
      }
      // Add time: confStartTime relative to confTimezone
      const [hours, minutes] = confStartTime.split(':');
      date = new Date(year, nextMonth, date.getDate(), +hours, +minutes, 0, 0);
      // Adjust when region is not UTC
      date.setUTCHours(+hours);
      date.setUTCMinutes(+minutes);
      // Restore original timezone offset
      if (isNaN(date)) {
        console.error('getNextMonthlyConferenceStart: date is not valid. confStartTime must be formatted HH:mm');
        return conferenceStartMoment;
      }
      if (timeZone) {
        return moment.tz(date.toISOString().replace('Z', ''), timeZone);
      }
      return moment(date.toISOString().replace('Z', '') + confTimezone);
    },
    /**
     * Formats a date string to a Date object or an ISO string.
     * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
     * @param {boolean} [asDateObject=true] Determines if the output should be a Date object. If false, returns an ISO string.
     * @returns {Date|string} The formatted Date object or ISO string.
     */
    formatCalendarAsDate(dateString, asDateObject = true) {
      if (dateString && typeof dateString === 'object' && dateString instanceof Date && !isNaN(dateString)) {
        return asDateObject ? new Date(dateString) : dateString.toISOString(); // Input is native date object
      }
      const [date, time] = (typeof dateString === 'string' && dateString.split(" ")) || []; // RFC 3339 on local time-offset
      if (dateString && !date && !time) {
        const momentDate = moment(dateString).toDate();
        return asDateObject ? momentDate : momentDate.toISOString(); // Try processing with moment.js
      }
      const obj = !time && typeof date === 'string' && date.includes("T") // ISO 8601 with embedded timezone
        ? new Date(date)
        : new Date(date
          .split("-")
          .map((e) => e.padStart(2, "0"))
          .join("-") +
          "T" +
          time
            .split(":")
            .map((e) => e.padStart(2, "0"))
            .join(":") +
          ":00" +
          getTimezone(date, time)
        );
      return asDateObject ? obj : obj.toISOString();
    },
    /**
     * Formats a date string to an ISO string.
     * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
     * @returns {string} The formatted ISO string.
     */
    formatCalendarDateToISO(dateString) {
      return this.formatCalendarAsDate(dateString, false);
    },
    formatDateString(dateString) {
      // Create a Date object from the input string
      const date = new Date(dateString);

      // Get the year, month, and day from the Date object
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0');

      // Get the hours and minutes
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      // Format the date as 'YYYY-MM-DD HH:mm'
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    getFormatedTime(time) {
      const date = new Date(time);
      return `${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;
    },
    prevMonth(){
      this.$refs.calendar.prev()
    },
    nextMonth(){
      this.$refs.calendar.next()
    },
    setDateinCaledar(date){
      const dateToSet = moment(new Date(date)).format('YYYY-MM-DD');
      this.calendarControls.setDate(dateToSet);
      this.calendarSelectedValue = [new Date(date)];
      this.refreshCurrentMonth();
    },
    refreshCurrentMonth(){
      if(this.calendarControls?.getDate()){
      const actualDate = new Date(this.calendarControls.getDate());
      const month = actualDate.getMonth() + 1;
      const year = actualDate.getFullYear();
      this.calendarMonth = this.$t(
        `components.calendarContainer.months.${month}`
      );
      this.calendarYear = year;
      }
    },
    setCalendarPreviousPage() {
      const now = this.calendarControls.getDate();
      switch (this.typeView) {
        case 'month': {
          // We take today and subtract a month
          const todayMinusOneMonth = moment(now).subtract(1, 'months').format('YYYY-MM-DD');
          this.setDateinCaledar(todayMinusOneMonth);
          // this.changeCalendarTypeView('month')
          break;
        }
        // In theory this calendar is always MonthView, left here as reference in case they finally want it
        // case 'week': {
        //   // We take today and subtract 7 days
        //   const todayMinusSevenDays = moment(now).subtract(7, 'days').format('YYYY-MM-DD');
        //   // this.calendarControls.setDate(todayMinusSevenDays);
        //   this.setDateinCaledar(todayMinusSevenDays);
        //   this.changeCalendarTypeView('week')
        //   break;
        // }
        // case 'day': {
        //   // We take today and subtract 1 day
        //   const todayMinusOneDay = moment(now).subtract(1, 'days').format('YYYY-MM-DD');
        //   // this.calendarControls.setDate(todayMinusOneDay);
        //   this.setDateinCaledar(todayMinusOneDay);
        //   this.changeCalendarTypeView('day')
        //   break;
        // }
        default: {
          // Default case logic here if necessary
          break;
        }
      }
      // this.refreshCurrentMonth();
    },
    setCalendarNextPage() {
      const now = this.calendarControls.getDate();
      switch (this.typeView) {
        case 'month': {
          // We take today and add a month
          const todayPlusOneMonth = moment(now).add(1, 'months').format('YYYY-MM-DD');
          this.setDateinCaledar(todayPlusOneMonth);
          // this.changeCalendarTypeView('month')
          break;
        }
        // In theory this calendar is always MonthView, left here as reference in case they finally want it
        // case 'week': {
        //   // We take today and add a 7 days more
        //   const todayPlusSevenDays = moment(now).add(7, 'days').format('YYYY-MM-DD');
        //   // this.calendarControls.setDate(todayPlusSevenDays);
        //   this.setDateinCaledar(todayPlusSevenDays);
        //   this.changeCalendarTypeView('week')
        //   break;
        // }
        // case 'day': {
        //   // We take today and add a 1 day more
        //   const todayPlusOneDay = moment(now).add(1, 'days').format('YYYY-MM-DD');
        //   // this.calendarControls.setDate(todayPlusOneDay);
        //   this.setDateinCaledar(todayPlusOneDay);
        //   this.changeCalendarTypeView('day')
        //   break;
        // }
        // default: {
        //   // Default case logic here if necessary
        //   break;
        // }
      }
      // this.refreshCurrentMonth();
    },
    setCalendarToday() {
      const now = new Date();
      const today = moment(now).format('YYYY-MM-DD')
      // this.changeCalendarTypeView(this.typeView);
      this.setDateinCaledar(today);
    },
    toggleHome(){
      if(this.$route.path === '/calendar'){
        this.gotoPage('/home');
      } else {
        this.gotoPage('/calendar');
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    getNameForUuid(uuid){
        return store.getNameForUuid(uuid)
    },
    getUserNameUserCalendar(uuid){
      if(uuid !==this.state.ownUUID){
        return this.getNameForUuid(uuid);
      }else {
        return '';
      }
    },
    syncTimeline({ date }){
      // get events for dates
      // alert(date)
      pleaseShowDateInTimelineEvent(date);

    },
    calChangeHandler(page) {
      const dateCounter = new Date(page.start.date);
      const endDate = new Date(page.end.date);
      this.calendarMonth = this.$t(`components.calendarContainer.months.${page.start.month}`);
      this.calendarYear = page.start.year;
      // eslint-disable-next-line no-unmodified-loop-condition
      while (dateCounter <= endDate) {
        dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
        dateCounter.setDate(dateCounter.getDate() + 1);
      }
    },
    saveNewEvent() {
      const dateTimeStart = this.startEvent + " " + this.timeStart;
      const dateTimeEnd = this.endEvent + " " + this.timeEnd;
      const newEntryInfo = {
        name: this.eventName,
        // start: this.formatDate(new Date(dateTimeStart), true),
        start: new Date(dateTimeStart),
        // end: this.formatDate(new Date(dateTimeEnd), true),
        end: new Date(dateTimeEnd),
        color: this.colorSelected.value,
        details: this.eventDetail,
        // uuid: uuidv4()
      };
      newTimelineItemEvent(newEntryInfo);
      // this.events.push(newEntryInfo);
      // setSectionCalendar(this.getUserCalendar, this.events);
      this.resetInputEvent();
    },
    async deleteEvent(entryUUID) {
      this.selectedOpen = false;
      this.wantDelete = false;
      deleteTimelineItemEvent(entryUUID);
      // deleteSectionCalendarEvent(this.getUserCalendar, entryUUID).then(events => {
      //   this.events = events;
      // });
    },
    resetInputEvent() {
      this.startEvent = "";
      this.startMenu = false;
      this.startTimeMenu = false;
      this.timeStart = null;
      this.endEvent = "";
      this.endMenu = false;
      this.endTimeMenu = false;
      this.timeEnd = null;
      this.colorSelected = {
        name: this.$t("components.calendarContainer.colors.blue"),
        value: "blue"
      },
      this.eventName = "";
      this.eventDetail = "";
    },
    showEvent({
      nativeEvent,
      event
    }) {
      if(event.clickable){
        if ( event.eventType && event.eventType == 'directCall'){
          setEventStateModalEvent(event);
        }else{
          this.$router.push({ path: `/newconference/${event.confId}` });
        }
      }else{
        if (event.eventType === 'external'){
          dispatchErrorAlert(this.$t('components.conferenceForm.externalConference'));
        } else {
          dispatchErrorAlert(this.$t('components.conferenceForm.reocurringConf'));
        }

      }

    },
    async fetchBlockedData() {
      let blockSlots = [];
      const blockedTimes = await getBlockedTimes();
      if (blockedTimes && Object.keys(blockedTimes).length){
        if (blockedTimes.apple && blockedTimes.apple.length){
          blockedTimes.apple.forEach(slot => {
            const blockSlot = {
              start: this.formatDateString(slot.start),
              end: this.formatDateString(slot.end),
              eventType: "external",
              name: 'Apple: ' + this.$t('components.conferenceForm.externalConference'),
              title: this.$t('components.conferenceForm.externalConference'),
              type: "external",
              color: "gray",
            };
            blockSlots.push(blockSlot);
          });
        }
       if (blockedTimes.outlook && blockedTimes.outlook.length){
          blockedTimes.outlook.forEach(slot => {
            const blockSlot = {
              start: this.formatDateString(slot.start),
              end: this.formatDateString(slot.end),
              eventType: "external",
              name: 'Outlook: ' + this.$t('components.conferenceForm.externalConference'),
              title: this.$t('components.conferenceForm.externalConference'),
              type: "external",
              color: "gray",
            };
            blockSlots.push(blockSlot);
          });
       }
      }
      this.myReservedEvents =  blockSlots;
    },
    // CHECK THIS
    getEventColor(event) {
      if ( event.childFromReocurring ) return 'blue';
      if (event.eventType === "external") return "gray"
      if ( !event.rsvp ){
        return event.color;
      }else{
        const usersAccepted = [];
        const usersRejected = [];
        for ( const userUUID of Object.keys(event.rsvp) ){
          const rsvp = event.rsvp[userUUID];
          if (rsvp && rsvp.status === 'accepted'){
            rsvp.color = 'green';
            usersAccepted.push(userUUID);
          }else if ( rsvp && rsvp.status === 'rejected' ){
            rsvp.color = 'red';
            usersRejected.push(userUUID);
          }else if ( rsvp && rsvp.status === 'pending' ){
            rsvp.color = event.color;
          }
        }
        const myUUID = this.uuidToRepresentStore || this.state.ownUUID
        if ( event.creatorUUID == myUUID && usersAccepted.length === Object.keys(event.rsvp).length ){
          return 'green';
        }else if ( event.creatorUUID == myUUID && usersRejected.length > 0 ) {
          return 'red';
        } else if ( event.rsvp[myUUID] ){
          return event.rsvp[myUUID].color;
        }else{
          return event.color
        }
      }
    },
    // rnd(a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a;
    // },
    formatDate(a, withTime) {
      return withTime ?
        `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}` :
        `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    displayHours(str) {
      const hours = str.split(/[ T]/)[1];
      return hours.split(":")
        .map((e) => e.padStart(2, "0"))
        .join(":");
    },
    updateRecurringEvents(){
      const formattedEvents = this.getFormattedEvents();
      formattedEvents.forEach( ev => {
        if (ev.rrule) {
          this.eventsServicePlugin.update(ev);
        }
      })
    }
  },
  watch: {
    isDarkMode: {
      deep: true,
      handler(value) {
        if (this.calendarApp) {
          this.calendarApp.setTheme(value ? 'dark' : 'light');
        }
      },
    },
    rawEvents: {
      deep: true,
      immediate: true,
        handler(value) {
          const delay = Promise.all([
            removedSplashPromise,
            syncedGroupState(true),
            syncedUserState().catch(() => {}),
          ]);
          this._xTimeout && clearTimeout(this._xTimeout);
          const timeout = this._xTimeout = setTimeout(async () => {
            try {
              await delay;
              if (timeout !== this._xTimeout) return console.log('race condition, skip...');
              if (!this.calendarApp) this.setCalendarApp();
            } catch (e) {
              console.warn('some error happened at the watch: ', e);
            }
          }, 900);
        },
    }
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    startCalendarDay(){
      return moment(Date.now()).format('YYYY-MM-DD');
    },
    endCalendarDate(){
      return moment(this.startCalendarDay, 'YYYY-MM-DD').add(30, 'days').format('YYYY-MM-DD');
    },
    sanitizeSelectedEventName() {
      return this.$sanitize(this.selectedEvent.name)
    },
    sanitizeSelectedEventDetails() {
      return this.$sanitize(this.selectedEvent.details)
    },
    userList() {
      return store.getProcessedUserList();
    },
    canAddEvent() {
      return !(
        this.eventName !== "" &&
        this.eventDetail !== "" &&
        this.startEvent !== "" &&
        this.timeStart &&
        this.endEvent !== "" &&
        this.timeEnd
      );
    },
    checkImSupervisor() {
      return true; // Fuck that
    },
  },
  mounted() {
    this.fetchBlockedData();
    this.eventsServicePlugin = createEventsServicePlugin();
    this.calendarControls = createCalendarControlsPlugin();
    this.createEventRecurrencePlugin = createEventRecurrencePlugin();
  },
};
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .h100 {
    height: 100%;
  }
  .calendarContainer {
    height: calc(100vh - 170px);
  }

  .workingTimeWrapper {
    display: flex;
  }
  .eventPreviewRow {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 560px);
  }
  .eventItem {
    padding: 0;
    display: flex;
  }

// .calendarSize {
//   height: calc(100vh - 170px);
//   overflow: auto;
//   padding-bottom: 20px;
// }
.btnCalendar{
  margin: 0px !important;
    color: white;
}
.text-white{
  color: white;
}
.calendarDark{
  background: #303030;
  border: 1px solid #9e9e9e;
  border-bottom: 0px;
}
.iconAddEvent{
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 5px;
}
.closeHome{
  position: absolute;
  right: 50px;
}
.refreshData{
  position: absolute;
  right: 20px;
}
</style>

<style lang="scss">
.gray{
  color:  white !important;
  background-color: rgb(175, 173, 173) !important;
}
.v-calendar-weekly__day.v-future, .v-calendar-weekly__day.v-present{
  cursor: pointer;
}
.v-calendar .v-event {
  margin-left: 6px!important;
  padding-left: 6px!important;
}
.calendarHeight {
  height: calc(100% - 100px) !important;
}
</style>
